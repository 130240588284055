import React from 'react';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export const ReadMore = ({ id, text, cssClass, words = 36 }) => {

    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const splittedText = text.split(' ');
    const itCanOverflow = splittedText.length > words;
    const beginText = itCanOverflow
        ? splittedText.slice(0, words - 1).join(' ')
        : text;
    const endText = splittedText.slice(words - 1).join(' ');

    const handleKeyboard = (e) => {
        if (e.code === 'Space' || e.code === 'Enter') {
            setIsExpanded(!isExpanded);
        }
    }

    return (
        <div id={id} className={`${cssClass}`}>
            {parse(beginText)}
            {itCanOverflow && (
                <>
                    {!isExpanded && <span>... </span>}
                    <span
                        className={`${!isExpanded && 'hidden'}`}
                        aria-hidden={!isExpanded}
                    >
                        {' '}{parse(endText)}
                    </span>
                    <span
                        className={`text-info ml-1`}
                        role="button"
                        tabIndex={0}
                        aria-expanded={isExpanded}
                        aria-controls={id}
                        onKeyDown={handleKeyboard}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? t('labels.showLess') : t('labels.showMore')}
                    </span>
                </>
            )}
        </div>
    )
}