import React, { useState } from 'react';
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalTitle from '../../../Modal/Title';
import * as CurrenciesThunks from '../../../../store/currencies/thunks';

const CurrencySelector = props => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const { currentCurrency, currencies } = props;

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleChangeCurrency = code => {
    dispatch(CurrenciesThunks.setCurrency(code));

    setShowModal(false);
  };

  const currencyOptions = (items, uid, mostUsed = false) => {
    return items.map((currency, index) => {
      if (mostUsed && !currency.mostUsed) {
        return null;
      }

      const key = `${uid}-currencies-${index}`;

      let cssClass = 'currency';

      if (currency.code === currentCurrency.code) {
        cssClass += ' current';
      }

      return (
        <li
          key={key}
          className={cssClass}
          onClick={() => {
            handleChangeCurrency(currency.code);
          }}
        >
          <span className="code">{currency.symbol}</span>
          <span className="link">{currency.name}</span>
        </li>
      );
    });
  };

  const mostUsedCurrencies = currencyOptions(currencies, 'most-used', true);
  const allCurrencies = currencyOptions(currencies, 'all');

  return (
    <div className="currency-selector">
      <span
        className="btn btn-raised btn-header-actions"
        onClick={handleShowModal}
      >
        <FontAwesomeIcon icon={faMoneyCheck} /> {currentCurrency.symbol}
      </span>

      <Modal
        size="lg"
        dialogClassName="modal-currency"
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as={ModalTitle}>
            {t('titles.mostUsedCurrencies')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled list-currency">{mostUsedCurrencies}</ul>
        </Modal.Body>
        <Modal.Header>
          <Modal.Title as={ModalTitle}>{t('titles.allCurrencies')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled list-currency">{allCurrencies}</ul>
          <p className="text-center d-block d-sm-none">
            <button onClick={handleCloseModal} type="button" className="btn btn-raised btn-default w-100 w-lg-auto">{t('labels.close')}</button>
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

CurrencySelector.propTypes = {
  currentCurrency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
};

export default CurrencySelector;
