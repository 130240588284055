import React from 'react';
import DeviceHelper from '../../helpers/device-helper';

const WhastsappFabButton = ({whatsapp, message}) => {
  const whatsappLinkPrefix = DeviceHelper.isMobile() ? `api` : `web`;
  const whatsappLink = `https://${whatsappLinkPrefix}.whatsapp.com/send?phone=${whatsapp}&text=${message}`;
  return <a href={whatsappLink} className="my-whatsapp-fab" target="_blank" rel="noopener noreferrer" alt={message}>
    <i className="fa fa-whatsapp my-whatsapp-fab-float"></i>
  </a>;
}
export default WhastsappFabButton;
