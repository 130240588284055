import i18next from 'i18next';
import { http } from '../../helpers/http';
import * as PropertyService from '../../services/Property';
import { setGlobalProperty, setLanguage, setUnknownProperty } from './actions';
import i18nHelper from '../../helpers/i18n-helper';
import { loadProperty } from '../property/thunks';
import ListHelper from '../../helpers/list-helper';

export const setCurrentLanguage = (
  code,
  arrival,
  departure,
  coupon,
  currency,
  adults,
  preSelectedRoom,
  utmSource,
  askSuiteId,
  widget,
  history
) => {
  return async dispatch => {
    i18next.changeLanguage(code).then(() => {
      dispatch(setLanguage(code));
      dispatch(
        loadProperty(
          arrival,
          departure,
          coupon,
          currency,
          adults,
          null,
          null,
          null,
          preSelectedRoom,
          utmSource,
          askSuiteId,
          widget,
          history,
          true
        )
      );
    });
  };
};

export const setConnectedProperty = (code, subpropertyCode, domain) => {
  return async (dispatch, getState) => {
    const {
      application: { ready },
    } = getState();

    if (ready) {
      return;
    }

    const [success, data] = await http.request(
      PropertyService.SERVICE_PROPERTY_CONNECT,
      {
        property: code,
        subproperty: subpropertyCode,
        propertyDomain: domain,
      }
    );

    if (success) {
      const {
        api_endpoint: apiHostelEndpoint,
        h: hostelUid,
        s: hostelSubpropertyUid,
        l: hostelLanguage,
        google_tag: gtm,
        available_languages,
      } = data;

      const availableLanguages = available_languages.map(language => {
        return {
          name: language.name,
          code: i18nHelper.fixLanguageCode(language.code),
          default: !!+language.is_default,
        };
      });

      let defaultHostelLanguage = i18nHelper.fixLanguageCode(hostelLanguage);
      const hqbookingsDefaultLanguage = available_languages.filter(
        language => language.is_default === 1
      )[0];

      if (hqbookingsDefaultLanguage && hqbookingsDefaultLanguage.code) {
        defaultHostelLanguage = hqbookingsDefaultLanguage.code;
      }

      if (defaultHostelLanguage === 'pt-br') defaultHostelLanguage = 'pt-BR';

      if (!i18nHelper.validLanguages.includes(defaultHostelLanguage)) {
        defaultHostelLanguage = i18nHelper.defaultLanguage;
      }

      http.updateHostelEndpoint(apiHostelEndpoint);

      i18nHelper.changeLanguage(defaultHostelLanguage).then(() => {});

      dispatch(
        setGlobalProperty(
          hostelUid,
          hostelSubpropertyUid,
          code,
          subpropertyCode,
          domain,
          defaultHostelLanguage,
          ListHelper.uniqueByKeepLast(availableLanguages, it => it.code ),
          gtm
        )
      );
    } else {
      dispatch(setUnknownProperty());
    }
  };
};
