import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const EditReservationLink = ({ isWidget, currentStep, onEditClick }) => {

    const { t } = useTranslation();

    if(!isWidget || currentStep === 'availability'){
        return '';
    }

    return (
        <div className="col-12 pt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
            <FontAwesomeIcon
                className="button-drop-shadow buttonColor"
                icon={faEdit}
                size='1x'
                onClick={onEditClick}
            />
            {t('labels.editReservation')}
        </div>
    );
};


export default EditReservationLink;



