import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FsLightbox from 'fslightbox-react';

const Gallery = props => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const { t } = useTranslation();
  const { hostel, photos } = props;
  const photosFiles = [];
  const maxPhotos = 6;

  const onOpenLightboxHandler = number => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  if (!photos || photos.length <= 0) {
    return null;
  }

  const gallery = photos.map((photo, index) => {
    const current = index + 1;
    const alt = `${hostel}, ${t('titles.photo')} ${current}`;

    photosFiles.push(photo.url);

    if (current > maxPhotos) {
      return null;
    }

    return (
      <span
        className="as-link"
        key={`footer-gallery-photo-${index + 1}`}
        onClick={() => onOpenLightboxHandler(current)}
      >
        <img src={photo.thumbnailUrl} alt={alt} />
      </span>
    );
  });

  return (
    <Fragment>
      <h4>{t('titles.photos')}</h4>
      <div className="photo-gallery">{gallery}</div>
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={photosFiles}
        type="image"
      />
    </Fragment>
  );
};

Gallery.propTypes = {
  hostel: PropTypes.string.isRequired,
  photos: PropTypes.array.isRequired,
};

export default Gallery;
