import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DateTimeHelper from '../../../../../helpers/date-time-helper';
import StringHelper from '../../../../../helpers/string-helper';

const CheckoutSummaryHeader = props => {
  const { t } = useTranslation();
  const { arrival, departure } = props;

  return (StringHelper.isNotEmpty(arrival)) ? (
    <div className="row pt-2 border-bottom">
      <div className="col">
        <h3 className="text-center">
          {DateTimeHelper.toDisplay(arrival, t)} {t('general.to')}{' '}
          {DateTimeHelper.toDisplay(departure, t)}
        </h3>
      </div>
    </div>
  ) : '';
};

CheckoutSummaryHeader.propTypes = {
  arrival: PropTypes.string.isRequired,
  departure: PropTypes.string.isRequired,
};

export default CheckoutSummaryHeader;
