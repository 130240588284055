export const SERVICE_CURRENCIES = {
  endpoint: '/currencies',
  method: 'get',
  hostel: true,
};

export const SERVICE_CURRENCY_CONVERSION_RATE = {
  endpoint: '/getCurrencyConversionRate',
  method: 'get',
  hostel: true,
};
