import * as ActionTypes from './action-types';
import UrlHelper from '../../helpers/url-helper';
import { STEP_AVAILABILITY } from '../../helpers/steps';

const initialState = {
  ready: false,
  loading: false,
  unknownProperty: false,
  currentStep: STEP_AVAILABILITY,
  finished: false,
  successMessage: '',
  tenantBookingId: null,
  paymentMethod: null,
  pixQrCode: null,
  pixPaymentLine: null,
  i18n: {
    ready: false,
    usingDefault: false,
    defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
    currentLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
    available: [],
  },
  property: {
    uid: null,
    code: null,
    gtm: null,
    domain: null,
    customDomainSlug: process.env.REACT_APP_CUSTOM_DOMAIN_SLUG || 'booking',
    defaultProperty: process.env.REACT_APP_DEFAULT_PROPERTY || '',
  },
  widget: null,
};

export default function reduce(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ActionTypes.SET_CURRENT_STEP: {
      UrlHelper.currentStep = payload.currentStep;

      const additional = {};

      if (payload.clearFinished) {
        additional.finished = false;
        additional.successMessage = '';
      }

      return { ...state, currentStep: payload.currentStep, ...additional };
    }

    case ActionTypes.SET_READY: {
      return { ...state, ready: payload.isReady };
    }

    case ActionTypes.SET_LOADING: {
      return { ...state, loading: payload.isLoading };
    }

    case ActionTypes.SET_LANGUAGE: {
      return {
        ...state,
        i18n: { ...state.i18n, currentLanguage: payload.language },
      };
    }

    case ActionTypes.SET_DEFAULT_LANGUAGE: {
      return {
        ...state,
        i18n: {
          ...state.i18n,
          ready: true,
          usingDefault: payload.usingDefault,
          defaultLanguage: payload.defaultLanguage,
        },
      };
    }

    case ActionTypes.SET_WIDGET: {
      return {
        ...state,
        widget: payload.widget,
      };
    }

    case ActionTypes.SET_CONNECTED_PROPERTY: {
      UrlHelper.propertyCode = payload.code;
      UrlHelper.subpropertyCode = payload.subpropertyCode;

      return {
        ...state,
        ready: true,
        i18n: {
          ...state.i18n,
          currentLanguage: payload.language,
          available: payload.available,
        },
        property: {
          ...state.property,
          uid: payload.uid,
          subpropertyUid: payload.subpropertyUid,
          code: payload.code,
          subpropertyCode: payload.subpropertyCode,
          gtm: payload.gtm,
          domain: payload.domain,
        },
      };
    }

    case ActionTypes.SET_UNKNOWN_PROPERTY: {
      return { ...state, unknownProperty: true };
    }

    case ActionTypes.SET_SUCCESS: {
      return {
        ...state,
        finished: true,
        successMessage: payload.successMessage,
        tenantBookingId: payload.tenantBookingId,
        paymentMethod: payload.paymentMethod,
        pixQrCode: payload.pixQrCode,
        pixPaymentLine: payload.pixPaymentLine,
      };
    }

    default: {
      return state;
    }
  }
}
