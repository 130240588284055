const LangHelper = {
    getBoardTypeLangKey: boardType => {
      let boardTypeLangKey = null;
      if (boardType === 0) {
        boardTypeLangKey = '';
      } else if (boardType === 1) {
        boardTypeLangKey = 'labels.withoutBreakfast';
      } else if (boardType === 2) {
        boardTypeLangKey = 'labels.withBreakfast';
      } else if (boardType === 3) {
        boardTypeLangKey = 'labels.halfBoard';
      } else if (boardType === 4) {
        boardTypeLangKey = 'labels.fullBoard';
      } else if (boardType === 5) {
        boardTypeLangKey = 'labels.allInclusive';
      }
  
      return boardTypeLangKey;
    },
  };
  
  export default LangHelper;
  