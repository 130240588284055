import httpClient from './http-client';
import httpHostelClient from './http-hostel-client';

export const http = {
  request: async (service, data = {}) => {
    const { endpoint = null, method = 'get', hostel = false } = service;

    const client = hostel ? httpHostelClient : httpClient;

    let response = null;
    let success = false;

    switch (method) {
      case 'get': {
        response = await client.get(endpoint, {
          params: data,
        });

        if (response && response.data && response.data.status) {
          success = true;
          response = response.data.result;
        } else if (response && response.data && !response.data.status) {
          success = false;
          response = response.data.result;
        } else {
          success = false;
          response = null;
        }

        break;
      }

      case 'post': {
        response = await client.post(endpoint, { ...data });

        if (response && response.data && response.data.status) {
          success = true;
          response = response.data.result;
        } else if (response && response.data && !response.data.status) {
          success = false;
          response = response.data.result;
        } else {
          success = false;
          response = null;
        }

        break;
      }

      default: {
        success = false;
        response = null;

        break;
      }
    }

    return [success, response];
  },

  updateHostelEndpoint: endpoint => {
    if (process.env.NODE_ENV.toLowerCase() === 'development') {
      httpHostelClient.defaults.baseURL = process.env.REACT_APP_API_URL;
    } else {
      httpHostelClient.defaults.baseURL = endpoint;
    }

    return true;
  },
};
