import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Amenity = props => {
  const { slug, label, icon } = props;

  return (
    <OverlayTrigger
      placement={'top'}
      overlay={<Tooltip id={`tooltip-${slug}`}>{label}</Tooltip>}
    >
      <span className={icon} />
    </OverlayTrigger>
  );
};

Amenity.propTypes = {
  slug: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default Amenity;
