import React from 'react';
import PropTypes from 'prop-types';
import About from './About';
import Coupon from './Coupon';
import CheckoutSummary from './CheckoutSummary';
import { useSelector } from 'react-redux';

const Sidebar = props => {

  const application = useSelector(state => state.application);
  const { widget } = application;
  const isWidget = widget ===  '1';

  const {
    property,
    reservation,
    canSendReservation,
    onSendReservation,
    page
  } = props;
  let { showAbout, showCoupon, showCheckoutSummary } = props;

  if (!property || isWidget) {
    showAbout = false;
  }

  if (!reservation) {
    showCoupon = false;
    showCheckoutSummary = false;
  }
  
  const sidebarStyles = isWidget ? { marginTop: `-4rem` } : {};

  return (
    <div className="col-12 col-md-3 mb-3 sidebar" style={sidebarStyles}>
      {showAbout && (
        <div className="card mt-md-n4 mb-3 about">
          <About
            name={property.name}
            about={property.aboutCallInfoText}
            latitude={property.latitude}
            longitude={property.longitude}
            street={property.address}
            city={property.city}
            state={property.state}
            phoneNumber={property.phoneNumber}
            email={property.email}
            page={page}
          />
        </div>
      )}

      <div className="summary-sticky">
        {showCheckoutSummary && (
          <div className="card mb-3">
            <CheckoutSummary
              canSendReservation={canSendReservation}
              onSendReservation={onSendReservation}
            />
          </div>
        )}

        {showCoupon && (
          <div className="card mb-3 cupom">
            <Coupon reservation={reservation} />
          </div>
        )}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  showAbout: PropTypes.bool,
  showCoupon: PropTypes.bool,
  showCheckoutSummary: PropTypes.bool,
  property: PropTypes.object,
  reservation: PropTypes.object,
  canSendReservation: PropTypes.bool,
  onSendReservation: PropTypes.func,
  page: PropTypes.string,
};

export default Sidebar;
