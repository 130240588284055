import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../../../images/logo_hqbeds_preto.png';

const ErrorHeader = props => {
  const { title, message } = props;

  return (
    <header>
      <div className="container">
        <div className="steps">
          <div className="row header greydark d-flex align-items-md-end justify-content-center">
            <div className="col-12 col-md-9">
              <h1>{title}</h1>
              <p>{message}</p>
            </div>
            <div className="logo-header col-12 col-md-3 text-center">
              <div className="country-options" />
              <span className="logo">
                <img src={logo} alt="HQBeds" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

ErrorHeader.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorHeader;
