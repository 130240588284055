export const SET_RESERVATION = 'reservation/SET_RESERVATION';
export const SET_RESERVATION_CLEANUP = 'reservation/SET_RESERVATION_CLEANUP';
export const SET_GUEST = 'reservation/SET_GUEST';
export const SET_PRODUCT = 'reservation/SET_PRODUCT';
export const SET_PRICE_VARIATORS = 'reservation/SET_PRICE_VARIATORS';
export const SET_CHECKOUT_SUMMARY = 'reservation/SET_CHECKOUT_SUMMARY';
export const SET_UTM_SOURCE = 'reservation/SET_UTM_SOURCE';
export const SET_UPDATE_CHECKOUT_SUMMARY = 'reservation/SET_UPDATE_CHECKOUT_SUMMARY';
export const SET_COUPON = 'reservation/SET_COUPON';
export const SET_IDENTIFICATION = 'reservation/SET_IDENTIFICATION';
export const SET_PAYMENT = 'reservation/SET_PAYMENT';
export const SET_PAYMENT_CREDIT_CARD = 'reservation/SET_PAYMENT_CREDIT_CARD';
export const SET_PAYMENT_BANK_TRANSFER = 'reservation/SET_PAYMENT_BANK_TRANSFER';
export const SET_PAYMENT_PIX = 'reservation/SET_PAYMENT_PIX';
export const SET_PAYMENT_CREDIT_CARD_DATA = 'reservation/SET_PAYMENT_CREDIT_CARD_DATA';
export const SET_ASKSUITE_ID = 'reservation/SET_ASKSUITE_ID';
