import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask/dist/reactTextMask';
import StringHelper from '../../../helpers/string-helper';

const FormInput = props => {
  const {
    id,
    name,
    label,
    value,
    transparent,
    white,
    textarea,
    uppercase,
    onInputChange,
    onInputRealChange,
    onInputFocus,
    masked,
    mask,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const useWhite = false;

  let groupClasses = 'form-group line-light bmd-form-group mb-0';
  let controlClasses = '';

  if (isFocused) {
    controlClasses = ' is-focused';
  }

  if (!isFocused && StringHelper.isNotEmpty(value)) {
    controlClasses = ' is-filled';
  }

  groupClasses += controlClasses;

  let inputClasses = 'form-control';
  let labelClasses = 'bmd-label-floating';

  if (uppercase) {
    inputClasses += ' text-uppercase';
    labelClasses += ' text-uppercase';
  }

  if (useWhite && white) {
    inputClasses += ' text-white';
  }

  if (transparent) {
    inputClasses += ' bg-transparent';
  }

  // region Events Handlers
  const onFocusHandler = () => {
    setIsFocused(true);

    if (typeof onInputRealChange === 'function') {
      onInputRealChange();
    }

    if (typeof onInputFocus === 'function') {
      onInputFocus();
    }
  };

  const onBlurHandler = () => {
    setIsFocused(false);

    if (typeof onInputRealChange === 'function') {
      onInputRealChange();
    }
  };

  const onChangeHandler = event => {
    const v = event.target.value;
    const n = event.target.name;

    onInputChange(v, n);
  };
  // endregion

  const inputProperties = {
    name,
    id,
    type: 'text',
    className: inputClasses,
    value,
    onFocus: onFocusHandler,
    onBlur: onBlurHandler,
    onChange: onChangeHandler,
  };

  if (textarea) {
    const textareaProperties = {
      name: inputProperties.name,
      id: inputProperties.id,
      className: inputProperties.className,
      value: inputProperties.value,
      rows: 5,
      onFocus: onFocusHandler,
      onBlur: onBlurHandler,
      onChange: onChangeHandler,
    };

    return (
      <div className={groupClasses}>
        <label htmlFor={id} className={labelClasses}>
          {label}
        </label>
        <textarea {...textareaProperties} />
      </div>
    );
  }

  return (
    <div className={groupClasses}>
      <label htmlFor={id} className={labelClasses}>
        {label}
      </label>
      {masked && (
        <MaskedInput
          {...inputProperties}
          mask={mask}
          guide={true}
          placeholderChar="_"
        />
      )}
      {!mask && <input {...inputProperties} />}
      <span className="msg-error" />
    </div>
  );
};

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  transparent: PropTypes.bool,
  white: PropTypes.bool,
  textarea: PropTypes.bool,
  uppercase: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onInputRealChange: PropTypes.func,
  onInputFocus: PropTypes.func,
  masked: PropTypes.bool,
  mask: PropTypes.array,
};

export default FormInput;
