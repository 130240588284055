import React from 'react';
import { useSelector } from 'react-redux';
import Stepper from './Stepper';
import LanguageSelector from './LanguageSelector';
import CurrencySelector from './CurrencySelector';
import StringHelper from '../../../helpers/string-helper';

const Header = () => {
  const application = useSelector(state => state.application);
  const property = useSelector(state => state.property);
  const currencies = useSelector(state => state.currencies);
  const reservation = useSelector(state => state.reservation);
  const i18n = useSelector(state => state.application.i18n);
  const { currentStep, finished, widget } = application;
  const { arrivalDate, departureDate, couponCode, askSuiteId, totalAdults: adults, totalChildren: children } = reservation;
  const isWidget = widget === '1';

  let headerStyle = {};
  if (!isWidget) {
    if (StringHelper.isNotEmpty(property.mainPhotoUrl)) {
      headerStyle = { backgroundImage: `url("${property.mainPhotoUrl}")` };
    }
  } else {
    return (<header style={{ marginTop: `60px`, height: `30px` }}></header>)
  }

  return (
    <header>
      <div className="hero">
        <div className="steps">
          <div
            className="row header greydarker d-flex align-items-md-end justify-content-center"
            style={headerStyle}
          >
            <>
              {!finished && <div className="container country-options-wrapper">
                <div className="country-options">
                  <LanguageSelector
                    currentLanguage={i18n.currentLanguage}
                    languages={i18n.available}
                  />
                  <CurrencySelector
                    currentCurrency={currencies.current}
                    currencies={currencies.options}
                  />
                </div>
              </div>}
              <div className="container stepper-logo-wrapper">
                <div className="col-12 col-md-9">
                  <Stepper
                    current={currentStep}
                    finished={finished}
                    arrivalDate={arrivalDate}
                    departureDate={departureDate}
                    couponCode={couponCode}
                    adults={adults}
                    children={children}
                    askSuiteId={askSuiteId}
                    widget={widget}
                  />
                </div>
                <div className="logo-header col-12 col-md-3 text-center">
                  {StringHelper.isNotEmpty(property.logoUrl) && (
                    <span className="logo">
                      <img src={property.logoUrl} alt={property.name} />
                    </span>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
