const ListHelper = {
    uniqueByKeepLast: (data, key) => {
        return [
            ...new Map(
                data.map(x => [key(x), x])
            ).values()
        ];
    }
}

export default ListHelper;
