import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Structure/Header';
import Footer from '../../components/Structure/Footer';
import MainContent from '../../components/Structure/Content/Main';
import Sidebar from '../../components/Structure/Sidebar';
import { setCurrentStep } from '../../store/application/actions';
import { STEP_SUCCESS } from '../../helpers/steps';
import StringHelper from '../../helpers/string-helper';
import UrlHelper from '../../helpers/url-helper';
import checkout from '../../images/checkout.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import ToasterHelper from '../../helpers/toaster-helper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const SuccessPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const property = useSelector(state => state.property);
  const application = useSelector(state => state.application);
  const { finished, successMessage, pixQrCode, pixPaymentLine, widget } = application;
  const reservation = useSelector(state => state.reservation);
  const { currentStep } = application;
  const [loaded, setLoaded] = useState(false);
  const isWidget = widget === '1';

  useEffect(() => {
    if (!finished || StringHelper.isEmpty(successMessage)) {
      history.push(UrlHelper.to(''));
    }
  }, [finished, successMessage, history]);

  if (!finished || StringHelper.isEmpty(successMessage)) {
    return null;
  }

  if (!loaded) {
    setLoaded(true);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (currentStep !== STEP_SUCCESS) {
      dispatch(setCurrentStep(STEP_SUCCESS, false));
    }
  });

  const handleNewReservationButtonClick = () => {
    history.push(UrlHelper.to(isWidget ? '?widget=1' : ''));
  }

  const sidebar = (
    <Sidebar property={property} reservation={reservation} showAbout />
  );

  return (
    <Fragment>
      <Header />

      <MainContent sidebar={sidebar}>
        <Fragment>
          <div className="card mt-n4 mb-4 checkout">
            <div className="card-body">
              <div className="row mt-3">
                <div className="col-12 text-center">
                  <h1 className="text-uppercase" style={{ lineHeight: '22px' }}>
                    {t('titles.successReservation')}
                  </h1>
                  <p className="h6 text-center mt-4 mb-4" style={{ fontSize: '18px' }}>
                    {successMessage}
                  </p>
                  <p className='h6' style={{ fontSize: '18px' }} >
                    {t('titles.thanks', { hostel: property.name })}
                  </p>
                </div>
              </div>
              {!StringHelper.isEmpty(pixQrCode) ?
                <div className="row d-flex flex-column align-items-center">
                  <div className='mt-5'>
                    {t('payment.pixMessageScan')}
                  </div>
                  <div>
                    <img width="250" src={pixQrCode} alt="QR Code" />
                  </div>
                  <div className='mt-5'>
                    {t('payment.pixMessageCopy')}
                  </div>
                  <div className='mt-2'>
                    <input
                      type="text"
                      readOnly="readOnly"
                      style={{ width: '320px', backgroundColor: '#A0ABC0', border: '0' }}
                      value={pixPaymentLine} />
                    <OverlayTrigger
                      placement={'top'}
                      overlay={<Tooltip id={`pixCode`}>{t('payment.pixKey')}</Tooltip>}
                    >
                      <FontAwesomeIcon className="ml-2" size='1x' icon={faCopy} style={{ cursor: 'pointer' }} onClick={() => {
                        navigator.clipboard.writeText(pixPaymentLine);
                        ToasterHelper.success(t('payment.pixMessageCopySuccess'));
                      }
                      } />
                    </OverlayTrigger>
                  </div>
                </div>
                :
                <div>
                  <div className="row">
                    <div className="col-12 text-center mb-5 mt-2">
                      <button className="btn btn-link text-lowercase first-capital-letter h6 text-secondary mb-4 mt-4" style={{ fontSize: '18px' }} onClick={handleNewReservationButtonClick}>
                        {t('titles.makeANewReservation')} <FontAwesomeIcon className="ml-2" icon={faHouse} />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 mx-auto">
                      <img src={checkout} alt="" className="img-fluid mb-5" id="checkout_success" />
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </Fragment>
      </MainContent>
      <Footer />
    </Fragment>
  );
};

export default SuccessPage;
