import { http } from '../../helpers/http';
import * as CountriesService from '../../services/Countries';
import { setCountries } from './actions';
import * as CurrenciesThunks from '../currencies/thunks';
import i18nHelper from '../../helpers/i18n-helper';

export const loadCountries = (preSetedByURLCurrency = null) => {
  return async (dispatch, getState) => {
    const {
      application: {
        ready,
        property: { uid, subpropertyUid },
        i18n: { currentLanguage },
      },
      countries: { ready: countriesReady },
    } = getState();

    if (!ready || countriesReady) {
      return;
    }

    const [success, data] = await http.request(
      CountriesService.SERVICE_COUNTRIES,
      {
        l: currentLanguage.toString().toLowerCase(),
        h: uid,
        s: subpropertyUid,
      }
    );

    if (success) {
      const countries = data.countries
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map(country => {
          return {
            id: +country.id,
            name: country.name,
            code: country.code,
            language: i18nHelper.fixLanguageCode(
              country.language,
              currentLanguage
            ),
          };
        });

      dispatch(setCountries(countries));
      dispatch(CurrenciesThunks.loadCurrencies(preSetedByURLCurrency));
    }
  };
};
