import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import FsLightbox from 'fslightbox-react';

import Photo from './Photo';
import StringHelper from '../../helpers/string-helper';
import ObjectHelper from '../../helpers/object-helper';

// region Helpers

const findMainPhoto = photos => {
  let mainPhoto;
  let usingMain = true;

  mainPhoto = photos.find(photo => {
    return photo.main;
  });

  if (mainPhoto === null) {
    usingMain = false;

    mainPhoto = photos.find((photo, index) => {
      return index === 0;
    });
  }

  return { photo: mainPhoto, usingMain };
};

const findSecondayPhotos = (photos, usingMain) => {
  let secondaryPhotos;

  if (usingMain) {
    const auxPhotos = photos.filter(photo => !photo.main);

    secondaryPhotos = auxPhotos.filter(
      (photo, index) => index === 0 || index === 1
    );
  } else {
    secondaryPhotos = photos.filter(
      (photo, index) => index === 1 || index === 2
    );
  }

  return secondaryPhotos;
};

const hasSecondaryPhotos = (firstPhoto, secondPhoto) => {
  return (
    firstPhoto &&
    firstPhoto.url &&
    StringHelper.isNotEmpty(firstPhoto.url) &&
    secondPhoto &&
    secondPhoto.url &&
    StringHelper.isNotEmpty(secondPhoto.url)
  );
};

// endregion

const MainGallery = props => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const { photos } = props;

  // region Events Handlers
  const photoOpenHandler = index => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index + 1,
    });
  };
  // endregion

  if (!photos || photos.length <= 0) {
    return null;
  }

  let photosFiles = [];

  const { photo: mainPhoto, usingMain } = findMainPhoto(photos);
  const [firstPhoto, secondPhoto] = findSecondayPhotos(photos, usingMain);
  const showSecondaryPhotos = hasSecondaryPhotos(firstPhoto, secondPhoto);
  const showMainPhoto =
    ObjectHelper.isObject(mainPhoto) &&
    ObjectHelper.propertyExists(mainPhoto, 'url');

  if (showMainPhoto) {
    photosFiles.push(mainPhoto.url);
  }

  if (showSecondaryPhotos) {
    photosFiles.push(firstPhoto.url);
    photosFiles.push(secondPhoto.url);
  }

  const otherPhotos = photos.filter(photo => !photosFiles.includes(photo.url));

  photosFiles = [...photosFiles, ...otherPhotos.map(photo => photo.url)];

  if (!showMainPhoto && !showSecondaryPhotos) {
    return null;
  }

  return (
    <Fragment>
      <div className="pictures">
        <div className="card-body pb-0">
          {showMainPhoto && (
            <div className="row mb-3">
              <div className="col-12">
                <Photo
                  photoUrl={mainPhoto.url}
                  label={mainPhoto.label}
                  onPhotoOpen={photoOpenHandler}
                  index={0}
                />
              </div>
            </div>
          )}
          {showSecondaryPhotos && (
            <div className="row mb-3">
              <div className="col-12 col-md-6 mb-3">
                <Photo
                  photoUrl={firstPhoto.url}
                  label={firstPhoto.label}
                  onPhotoOpen={photoOpenHandler}
                  index={1}
                />
              </div>
              <div className="col-12 col-md-6">
                <Photo
                  photoUrl={secondPhoto.url}
                  label={secondPhoto.label}
                  onPhotoOpen={photoOpenHandler}
                  index={2}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={photosFiles}
        captions={[
          'First image title',
          'Second image title',
          'Third image caption',
        ]}
        type="image"
      />
    </Fragment>
  );
};

MainGallery.propTypes = {
  photos: PropTypes.array.isRequired,
};

export default MainGallery;
