const StringHelper = {
  isEmpty: text => {
    return !StringHelper.isNotEmpty(text);
  },

  isNotEmpty: text => {
    return (
      typeof text !== 'undefined' &&
      text !== undefined &&
      text !== null &&
      text.toString().trim() !== ''
    );
  },

  justNumbers: text => {
    return text.replace(/([^\d]+)/gi, '');
  },

  hexToRgb: hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  },

  stripHtml: text => {
    return text.replace(/(<([^>]+)>)/gi, "");
  }
};

export default StringHelper;
