const ObjectHelper = {
  isObject: obj => {
    return typeof obj !== 'undefined' && obj !== null;
  },

  isEmpty: obj => {
    return !ObjectHelper.isObject(obj) || Object.keys(obj).length <= 0;
  },

  propertyExists: (obj, prop) => {
    return (
      ObjectHelper.isObject(obj) &&
      obj[prop] &&
      typeof obj[prop] !== 'undefined' &&
      obj[prop] !== null
    );
  },

  propertyOrNull: (obj, prop) => {
    if (ObjectHelper.propertyExists(obj, prop)) {
      return obj[prop];
    }

    return null;
  },

  propertyOrDefault: (obj, prop, def = null) => {
    if (ObjectHelper.propertyExists(obj, prop)) {
      return obj[prop];
    }

    return def;
  },

  pod: (obj, prop, def = null) => {
    return ObjectHelper.propertyOrDefault(obj, prop, def);
  },

  asInt: (obj, prop, def = null) => {
    const value = ObjectHelper.propertyOrDefault(obj, prop, def);

    return +value;
  },
};

export default ObjectHelper;
