import NumberHelper from "./number-helper";
import StringHelper from "./string-helper";

const DatalayerHelper = {

  /**
   * Send the add/remove to cart event to dataLayer.
   * 
   * @param string  operation 
   * @param object  property 
   * @param object  reservation 
   * @param string  itemId 
   * @param string  itemName 
   * @param double  itemFinalPrice 
   * @param integer guestsQuantity 
   * @param integer itemQuantity 
   * @param string  ratePlanTitle
   */
  handleCartEvent: (
    operation,
    property,
    reservation,
    itemId,
    itemName,
    itemFinalPrice,
    guestsQuantity,
    itemQuantity,
    ratePlanTitle
  ) => {
    const event_name = operation === 'add' ? 'add_to_cart' : 'remove_to_cart';
    const reservationValue = itemFinalPrice * itemQuantity;
    const addToCartDataLayer = {
      event: event_name,
      currency: property.countryCurrencyCode,
      value: NumberHelper.toFloat(reservationValue).toFixed(2),
      coupon: reservation.coupon_code,
      nights: NumberHelper.toInt(reservation.totalNights),
      items: [{
        item_id: itemId,
        item_name: itemName,
        rate_plan_name: StringHelper.isNotEmpty(ratePlanTitle) ? ratePlanTitle : 'Standard',
        coupon: reservation.coupon_code,
        price: NumberHelper.toFloat(itemFinalPrice).toFixed(2),
        guests: NumberHelper.toInt(guestsQuantity),
        quantity: NumberHelper.toInt(itemQuantity),
      }],
    };

    if (window.dataLayer) {
      window.dataLayer.push(addToCartDataLayer);
    }
  },

  /**
  * Send the begin checkout event to dataLayer.
  * @param object  property
  * @param string  coupon_code
  * @param integer totalNights
  * @param object  rooms
  */
  sendBeginCheckoutEvent: (property, coupon_code, totalNights, rooms) => {
    const items = rooms?.map(
      item => {
        return {
          item_id: item.id,
          item_name: item.name,
          rate_plan_name: StringHelper.isNotEmpty(item.ratePlanTitle) ? item.ratePlanTitle : 'Standard',
          coupon: coupon_code,
          price: item.totalPrice,
          guests: NumberHelper.toInt(item.realTotalGuests),
          quantity: 1
        };
      }
    );

    let reservationValue = rooms?.reduce(function (tot, arr) {
      return tot + arr.totalPrice;
    }, 0);

    const beginCheckoutDataLayer = {
      event: 'begin_checkout',
      currency: property.countryCurrencyCode,
      value: NumberHelper.toFloat(reservationValue).toFixed(2),
      coupon: coupon_code,
      nights: NumberHelper.toInt(totalNights),
      items: items
    };

    if (window.dataLayer) {
      window.dataLayer.push(beginCheckoutDataLayer);
    }
  },

  /**
   * Send the conversion event to dataLayer.
   * @param integer newTenantBookingId
   * @param object  reservation
   */
  sendConversionEvent: (newTenantBookingId, reservation) => {
    const reservedRooms = Object.entries(reservation.reserve).map(
      (entry, index) => {
        var itemId = entry[0].split('_')[0];
        return {
          id: itemId,
          name: entry[1].description,
          price: entry[1].price,
          quantity: entry[1].units,
        };
      }
    );

    const conversionDataLayer = {
      event: 'purchase',
      name: reservation.name,
      surname: reservation.surname,
      email: reservation.email,
      country: reservation.country,
      arrival: reservation.arrival,
      departure: reservation.departure,
      eventDetails: {
        category: 'Ecommerce',
        action: 'Purchase',
      },
      ecommerce: {
        purchase: {
          actionField: {
            id: newTenantBookingId,
            revenue: reservation.total,
            tax: 0,
            shipping: 0,
            coupon: reservation.coupon_code,
            action: 'purchase',
          },
          products: reservedRooms,
        },
      },
    };

    if (window.dataLayer) {
      window.dataLayer.push(conversionDataLayer);
    }
  },
};

export default DatalayerHelper;
