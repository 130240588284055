import React from 'react';
import PropTypes from 'prop-types';

const MainContent = props => {
  const { children, sidebar, isWidget } = props;

  const mainDivClasses = isWidget ? 'col-12' : 'col-12 col-md-9';
  return (
    <section>
      <div className="container pb-4">
        <main>
          <div className="row">
            <div className={mainDivClasses}>{children}</div>
            {sidebar}
          </div>
        </main>
      </div>
    </section>
  );
};

MainContent.propTypes = {
  sidebar: PropTypes.element.isRequired,
};

export default MainContent;
