import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextXhrBackend from 'i18next-xhr-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(I18NextXhrBackend)
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    interpolation: {
      escapeValue: false,
    },

    lng: 'en',

    fallbackLng: 'en',

    debug: false,

    detection: {
      order: ['querystring', 'cookie', 'localStorage'],

      lookupQuerystring: 'lang',
      lookupCookie: process.env.REACT_APP_LANGUAGE_CACHE_KEY || 'lang',
      lookupLocalStorage: process.env.REACT_APP_LANGUAGE_CACHE_KEY || 'lang',

      caches: [],
      excludeCacheFor: ['cimode'],

      checkWhitelist: true,
    },

    whitelist: ['en', 'es', 'pt-BR'],
  })
  .then(() => {});

export default i18next;
