import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const About = props => {
  const { t } = useTranslation();
  const { text } = props;

  if (text === null || text === '') {
    return null;
  }

  return (
    <Fragment>
      <h4>{t('titles.aboutHostel')}</h4>
      <div>{parse(text)}</div>
    </Fragment>
  );
};

About.propTypes = {
  text: PropTypes.string.isRequired,
};

export default About;
