import * as ActionTypes from './action-types';

export const setReservation = (
  arrivalDate,
  departureDate,
  totalNights,
  totalAdults,
  totalChildren,
  children,
  rooms,
  products,
  percentageDiscount,
  valueDiscount,
  additionalTax,
  priceVariators
) => ({
  type: ActionTypes.SET_RESERVATION,
  payload: {
    loaded: true,
    ready: false,
    arrivalDate,
    departureDate,
    totalNights,
    totalAdults,
    totalChildren,
    children,
    rooms,
    products,
    percentageDiscount,
    valueDiscount,
    additionalTax,
    priceVariators,
  },
});

export const setReservationCleanup = () => ({
  type: ActionTypes.SET_RESERVATION_CLEANUP,
  payload: {},
});

export const setCheckoutSummary = (
  totalNights,
  installmentRate,
  depositPercentage
) => ({
  type: ActionTypes.SET_CHECKOUT_SUMMARY,
  payload: {
    totalNights,
    installmentRate,
    depositPercentage,
  },
});

export const setUtmSource = utmSource => ({
  type: ActionTypes.SET_UTM_SOURCE,
  payload: {
    utmSource,
  },
});

export const setAskSuiteId = askSuiteId => ({
  type: ActionTypes.SET_ASKSUITE_ID,
  payload: {
    askSuiteId,
  },
});

export const setUpdateCheckoutSummary = availabilityRooms => ({
  type: ActionTypes.SET_UPDATE_CHECKOUT_SUMMARY,
  payload: {
    availabilityRooms,
  },
});

export const setGuest = (
  id,
  typeId,
  salesBaseIndex,
  salesBase,
  salesBasePrice,
  salesBaseOriginalPrice,
  totalGuests,
  totalNights,
  cleaningTax,
  ratePlanRealId,
  roomTypeRealId,
  ratePlanBoardType
) => ({
  type: ActionTypes.SET_GUEST,
  payload: {
    id,
    typeId,
    salesBaseIndex,
    salesBase,
    salesBasePrice,
    salesBaseOriginalPrice,
    totalGuests,
    totalNights,
    cleaningTax,
    ratePlanRealId,
    roomTypeRealId,
    ratePlanBoardType
  },
});

export const setCoupon = (
  code,
  percentageDiscount,
  valueDiscount,
  isAccumulative
) => ({
  type: ActionTypes.SET_COUPON,
  payload: {
    code,
    percentageDiscount,
    valueDiscount,
    isAccumulative,
  },
});

export const setPriceVariators = priceVariators => ({
  type: ActionTypes.SET_PRICE_VARIATORS,
  payload: {
    priceVariators,
  },
});

export const setProduct = (id, total) => ({
  type: ActionTypes.SET_PRODUCT,
  payload: {
    id,
    total,
  },
});

export const setIdentification = identification => ({
  type: ActionTypes.SET_IDENTIFICATION,
  payload: {
    ...identification,
  },
});

export const setPayment = payment => ({
  type: ActionTypes.SET_PAYMENT,
  payload: {
    ...payment,
  },
});

export const setPaymentCreditCard = use => ({
  type: ActionTypes.SET_PAYMENT_CREDIT_CARD,
  payload: {
    use,
  },
});

export const setPaymentPix = use => ({
  type: ActionTypes.SET_PAYMENT_PIX,
  payload: {
    use,
  },
});

export const setPaymentBankTransfer = use => ({
  type: ActionTypes.SET_PAYMENT_BANK_TRANSFER,
  payload: {
    use,
  },
});

export const setPaymentCreditCardData = data => ({
  type: ActionTypes.SET_PAYMENT_CREDIT_CARD_DATA,
  payload: {
    ...data,
  },
});
