import * as ActionTypes from './action-types';

export const setCurrentCurrency = (code, rate) => ({
  type: ActionTypes.SET_CURRENT_CURRENCY,
  payload: { code, rate },
});

export const setCurrencies = currencies => ({
  type: ActionTypes.SET_CURRENCIES,
  payload: [...currencies],
});
