import { http } from '../../helpers/http';
import * as AvailabilityService from '../../services/Availability';
import * as AvailabilityActions from './actions';
import * as ProductsThunks from '../products/thunks';
import * as TravelActions from '../travel/actions';
import * as ReservationActions from '../reservation/actions';
import ObjectHelper from '../../helpers/object-helper';
import NumberHelper from '../../helpers/number-helper';
import StringHelper from '../../helpers/string-helper';
import ToasterHelper from '../../helpers/toaster-helper';

export const fetchAvailability = (
  arrival,
  departure,
  couponCode,
  adults,
  totalChildren,
  children,
  callback,
  errorCallback,
  forceUpdate = false
) => {
  return async (dispatch, getState) => {
    const {
      application: {
        ready: applicationReady,
        property: { uid, subpropertyUid },
        i18n: { currentLanguage },
      },
      property: {
        config: { additionalTax },
      },
      travel: {
        loaded: travelLoaded,
        arrivalDate: travelArrivalDate,
        departureDate: travelDepartureDate,
        totalNights: travelTotalNights,
        totalAdults: travelTotalAdults,
        totalChildren: travelTotalChildren,
        children: travelChildren,
      },
    } = getState();

    if (!applicationReady || (travelLoaded && !forceUpdate)) {
      return;
    }

    const [success, data] = await http.request(
      AvailabilityService.SERVICE_FETCH,
      {
        l: currentLanguage.toString().toLowerCase(),
        h: uid,
        s: subpropertyUid,
        arrival,
        departure,
        coupon: StringHelper.isNotEmpty(couponCode) ? couponCode : '',
        adults,
        totalChildren,
        children,
      }
    );

    if (success) {
      const {
        availability: availableRooms,
        reservation_max_nights: reservationMaxNights,
        reservation_max_guests: reservationMaxGuests,
      } = data;

      let rooms = [];

      if (
        Array.isArray(availableRooms) ||
        ObjectHelper.isObject(availableRooms)
      ) {
        rooms = Object.keys(availableRooms)
          .map(k => availableRooms[k])
          .map(room => {
            const details = ObjectHelper.pod(room, 'allInfo', []);
            const roomId = +ObjectHelper.pod(details, 'id', 0);
            const baseAmenities = ObjectHelper.pod(details, 'amenityList', {});
            const basePhotos = ObjectHelper.pod(details, 'photos', []);
            const baseSalesRooms = ObjectHelper.pod(
              room,
              'sales_base_rooms',
              {}
            );
            const baseHostelRates = ObjectHelper.pod(room, 'diaries', {});
            const baseHostelOriginalRates = ObjectHelper.pod(
              room,
              'diaries__original',
              {}
            );

            // region Hostel Rates
            let hostelRates = [];

            if (Object.keys(baseHostelRates).length > 0) {
              hostelRates = Object.keys(baseHostelRates).map(k => {
                const internalRates = baseHostelRates[k];

                let rates = [];

                if (Object.keys(internalRates).length > 0) {
                  rates = Object.keys(internalRates).map(kr => {
                    return {
                      id: `${roomId}_${kr}`,
                      base: +kr,
                      price: NumberHelper.toFloat(internalRates[kr]),
                    };
                  });
                }

                return {
                  date: k,
                  rates,
                };
              });
            }
            // endregion

            // region Hostel Original Rates
            let hostelOriginalRates = [];

            if (Object.keys(baseHostelOriginalRates).length > 0) {
              hostelOriginalRates = Object.keys(baseHostelOriginalRates).map(
                k => {
                  const internalRates = baseHostelRates[k];

                  let rates = [];

                  if (Object.keys(internalRates).length > 0) {
                    rates = Object.keys(internalRates).map(kr => {
                      return {
                        id: `${roomId}_${kr}`,
                        base: +kr,
                        price: NumberHelper.toFloat(internalRates[kr]),
                      };
                    });
                  }

                  return {
                    date: k,
                    rates,
                  };
                }
              );
            }
            // endregion

            // region Gallery
            let mainPhoto = null;
            let hasMainPhoto = false;
            let gallery = [];

            if (basePhotos.length > 0) {
              gallery = basePhotos.map(photo => {
                return {
                  url: ObjectHelper.pod(photo, 'storage_path', ''),
                  thumbnailUrl: ObjectHelper.pod(
                    photo,
                    'thumbnail_storage_path',
                    ''
                  ),
                  main: !!+ObjectHelper.pod(photo, 'main_photo', 0),
                  legend: ObjectHelper.pod(photo, 'legend', ''),
                };
              });

              mainPhoto = gallery.find(photo => photo.main);

              if (ObjectHelper.isObject(mainPhoto)) {
                hasMainPhoto = true;
              } else {
                mainPhoto = gallery.find((photo, index) => index === 0);

                if (ObjectHelper.isObject(mainPhoto)) {
                  hasMainPhoto = true;
                }
              }
            }
            // endregion

            // region Sales Base Rooms
            let salesBaseRooms = [];

            if (baseSalesRooms.length > 0) {
              salesBaseRooms = baseSalesRooms.map(saleBase => {
                return {
                  id: +ObjectHelper.pod(saleBase, 'id', null),
                  base: +ObjectHelper.pod(saleBase, 'base', null),
                  discount: NumberHelper.toFloat(
                    ObjectHelper.pod(saleBase, 'discount', 0)
                  ),
                  unitPrice: NumberHelper.toFloat(
                    ObjectHelper.pod(saleBase, 'unit_price', 0)
                  ),
                  originalUnitPrice: NumberHelper.toFloat(
                    ObjectHelper.pod(saleBase, 'original_unit_price', 0)
                  ),
                  childrenTotal: NumberHelper.toFloat(
                    ObjectHelper.pod(saleBase, 'children_total', 0)
                  ),
                  rooms: +ObjectHelper.pod(saleBase, 'id', null),
                };
              });
            }
            // endregion

            // region Amenities
            let amenities = [];

            if (Object.keys(baseAmenities).length > 0) {
              amenities = Object.keys(baseAmenities).map(k => {
                return {
                  id: k,
                  icon: ObjectHelper.pod(baseAmenities[k], 'icon', ''),
                  slug: ObjectHelper.pod(baseAmenities[k], 'slug', ''),
                  label: ObjectHelper.pod(baseAmenities[k], 'label', ''),
                };
              });

              amenities = amenities.filter(
                amenity => amenity.label.toString() !== ''
              );
            }
            // endregion

            return {
              id: roomId,
              typeId: +ObjectHelper.pod(room, 'room_type_id', 0),
              roomTypeRealId: +ObjectHelper.pod(room, 'rt_real_id', 0),
              ratePlanRealId: +ObjectHelper.pod(room, 'rp_real_id', 0),
              adults: ObjectHelper.pod(room, 'adults', ''),
              childrenRegular: ObjectHelper.pod(room, 'children_regular', ''),
              childrenAdditional: ObjectHelper.pod(room, 'children_additional', ''),
              name: ObjectHelper.pod(details, 'roomName', ''),
              description: ObjectHelper.pod(details, 'roomDescription', ''),
              ratePlanTitle: ObjectHelper.pod(room, 'rate_plan_title', ''),
              ratePlanTerms: ObjectHelper.pod(room, 'rate_plan_terms', ''),
              ratePlanBoardType: ObjectHelper.pod(room, 'rate_plan_board_type', 0),
              ratePlanBoardCharge: ObjectHelper.pod(room, 'rate_plan_board_charge', 0),
              ratePlanBoardPrice: ObjectHelper.pod(room, 'rate_plan_board_price', 0.0),
              observations: ObjectHelper.pod(
                room,
                'availability_observation',
                ''
              ),
              typeName: ObjectHelper.pod(room, 'name', ''),
              capacity: +ObjectHelper.pod(details, 'capacity', 0),
              beds: +ObjectHelper.pod(details, 'beds', 0),
              available: +ObjectHelper.pod(room, 'available', 0),
              gender: ObjectHelper.pod(details, 'gender', ''),
              doubleBed: !!+ObjectHelper.pod(details, 'double_bed', 0),
              ensuite: !!+ObjectHelper.pod(details, 'ensuite', 0),
              privateRoom: !!+ObjectHelper.pod(details, 'private_room', 0),
              cleaningTax: NumberHelper.toFloat(
                ObjectHelper.pod(details, 'cleaning_tax', 0)
              ),
              amenities,
              mainPhoto,
              hasMainPhoto,
              gallery,
              price: NumberHelper.toFloat(ObjectHelper.pod(room, 'price', 0)),
              originalPrice: NumberHelper.toFloat(
                ObjectHelper.pod(room, 'originalPrice', 0)
              ),
              unitPrice: NumberHelper.toFloat(
                ObjectHelper.pod(room, 'unit_price', 0)
              ),
              discount: NumberHelper.toFloat(
                ObjectHelper.pod(room, 'hqbookings_discount', 0)
              ),
              units: +ObjectHelper.pod(room, 'room_units', 0),
              salesBaseRooms,
              hostelRates,
              hostelOriginalRates,
            };
          });
      }

      const reservationRooms = rooms.map((room, index) => {
        return {
          index,
          id: room.id,
          name: room.name,
          privateRoom: room.privateRoom,
          adults: room.adults,
          children: room.children,
          childrenAdditional: room.childrenAdditional,
          childrenRegular: room.childrenRegular,
          typeId: room.typeId,
          roomTypeRealId: room.roomTypeRealId,
          ratePlanRealId: room.ratePlanRealId,
          ratePlanTitle: room.ratePlanTitle,
          ratePlanBoardType: room.ratePlanBoardType,
          guests: [],
        };
      });

      const couponData = ObjectHelper.pod(data, 'coupon_info', {});

      let hasCoupon = false;
      let isCouponValid = false;
      let couponMessage = '';
      let currentCouponCode = '';
      let isCouponAccumulative = false;

      if (ObjectHelper.isObject(couponData)) {
        hasCoupon = ObjectHelper.pod(couponData, 'has_coupon', false);
        isCouponValid = ObjectHelper.pod(couponData, 'is_valid', false);
        couponMessage = ObjectHelper.pod(couponData, 'msg', '');
        currentCouponCode = ObjectHelper.pod(couponData, 'code', '');
        isCouponAccumulative = ObjectHelper.pod(
          couponData,
          'accumulative',
          false
        );
      }

      if (hasCoupon && !isCouponValid) {
        ToasterHelper.error(couponMessage);
        currentCouponCode = '';
      }

      const percentageDiscount = NumberHelper.toFloat(
        ObjectHelper.pod(data, 'discount_percentage', 0)
      );

      const valueDiscount = NumberHelper.toFloat(
        ObjectHelper.pod(data, 'discount_real', 0)
      );

      const priceVariators = ObjectHelper.pod(data, 'price_variators', null);

      dispatch(
        ProductsThunks.fetchProducts(
          (reservationProducts, force) => {
            dispatch(TravelActions.setIsLoaded(true));

            if (!force) {
              dispatch(
                ReservationActions.setReservation(
                  travelArrivalDate,
                  travelDepartureDate,
                  travelTotalNights,
                  travelTotalAdults,
                  travelTotalChildren,
                  travelChildren,
                  reservationRooms,
                  reservationProducts,
                  percentageDiscount,
                  valueDiscount,
                  additionalTax,
                  priceVariators
                )
              );
            }

            dispatch(
              ReservationActions.setCoupon(
                currentCouponCode,
                percentageDiscount,
                valueDiscount,
                isCouponAccumulative
              )
            );

            dispatch(ReservationActions.setPriceVariators(priceVariators));
            dispatch(ReservationActions.setUpdateCheckoutSummary(rooms));
            dispatch(ReservationActions.setCheckoutSummary());

            dispatch(
              AvailabilityActions.setRooms(
                rooms,
                reservationMaxNights,
                reservationMaxGuests,
                travelArrivalDate,
                travelDepartureDate,
                travelTotalNights,
                travelTotalAdults,
                travelTotalChildren,
                travelChildren
              )
            );

            callback();
          },
          () => {
            dispatch(AvailabilityActions.setRoomsCleanup());
            dispatch(ReservationActions.setReservationCleanup());
            errorCallback();
          },
          forceUpdate
        )
      );
    } else {
      dispatch(AvailabilityActions.setRoomsCleanup());
      dispatch(ReservationActions.setReservationCleanup());
      errorCallback();
    }
  };
};
