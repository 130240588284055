import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import { useTranslation } from 'react-i18next';
import { history } from '../../store/Redux';
import Views from '../../views';
import Loading from '../../components/Loading';
import i18next from '../../helpers/i18n';
import i18nHelper from '../../helpers/i18n-helper';
import * as ApplicationActions from '../../store/application/actions';
import 'react-dates/lib/css/_datepicker.css';
import 'toasted-notes/src/styles.css';
import './index.scss';
import 'react-dates/initialize';
import StringHelper from '../../helpers/string-helper';
import ScrollToTop from '../../components/ScrollToTop';
import 'react-credit-cards/es/styles-compiled.css';
import WhastsappFabButton from '../../components/WhatsappFabButton';

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [languageEventStarted, setLanguageEventStarted] = useState(false);
  const [gtmStarted, setGtmStarted] = useState(false);
  const application = useSelector(state => state.application);
  const property = useSelector(state => state.property);
  const { ready, loading, unknownProperty, widget } = application;
  const { ready: languageReady, defaultLanguage } = application.i18n;
  const { gtm} = application.property;
  const defaultGtm = process.env.REACT_APP_GTM;
  const mainColor = useSelector(state => state.property.config.mainColor);
  const currencies = useSelector(state => state.currencies);
  const isWidget = widget === '1';
  
  const {
    current: { code: currencyCode },
  } = currencies;
  const [, setCurrency] = useState(currencyCode);

  useEffect(() => {
    const { referrer } = document;
    const { href } = window.location;

    // eslint-disable-next-line no-undef
    if (referrer != null && !localStorage.getItem('originalReferrer')) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('originalReferrer', referrer);
    }
    // eslint-disable-next-line no-undef
    if (href != null && !localStorage.getItem('originalSource')) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('originalSource', href);
    }
  });

  useEffect(() => {
    const rgbMainColor = StringHelper.hexToRgb(mainColor);
    if (rgbMainColor !== null) {
      document.documentElement.style.setProperty(
        '--primary',
        `rgba(${rgbMainColor.r}, ${rgbMainColor.g}, ${rgbMainColor.b}, 1)`
      );
      document.documentElement.style.setProperty(
        '--primary2',
        `rgba(${rgbMainColor.r}, ${rgbMainColor.g}, ${rgbMainColor.b}, .5)`
      );
    }
  }, [mainColor]);

  useEffect(() => {
    setCurrency(currencyCode);
  }, [currencyCode]);

  if (!languageReady) {
    const usingDefaultLanguage = i18nHelper.isUsingDefaultLanguage();
    dispatch(
      ApplicationActions.setIsDefaultLanguage(
        usingDefaultLanguage ? i18next.language : defaultLanguage,
        usingDefaultLanguage
      )
    );
  }

  if (!languageEventStarted) {
    setLanguageEventStarted(true);
    i18next.on('languageChanged', lng => {
      moment.locale(lng.toString().toLowerCase());
    });
  }

  let routes = '';

  if (ready) {
    if (!gtmStarted) {
      TagManager.initialize({
        gtmId: StringHelper.isNotEmpty(gtm) ? gtm : defaultGtm,
      });
      setGtmStarted(true);
    }

    routes = (
      <Switch>
        {/* Default Property */}
        <Route
          key="home"
          name={t('titles.home')}
          path="/:property"
          exact
          component={Views.HomePage}
        />
        <Route
          key="rooms"
          name={t('titles.rooms')}
          path="/:property/rooms"
          exact
          component={Views.RoomsPage}
        />
        <Route
          key="products"
          name={t('titles.products')}
          path="/:property/products"
          exact
          component={Views.ProductsPage}
        />
        <Route
          key="checkout"
          name={t('titles.checkout')}
          path="/:property/checkout"
          exact
          component={Views.CheckoutPage}
        />
        <Route
          key="success"
          name={t('titles.success')}
          path="/:property/success"
          exact
          component={Views.SuccessPage}
        />

        {/* Hqrooms Property */}
        <Route
          key="home"
          name={t('titles.home')}
          path="/:property/:subproperty"
          exact
          component={Views.HomePage}
        />
        <Route
          key="rooms"
          name={t('titles.rooms')}
          path="/:property/:subproperty/rooms"
          exact
          component={Views.RoomsPage}
        />
        <Route
          key="products"
          name={t('titles.products')}
          path="/:property/:subproperty/products"
          exact
          component={Views.ProductsPage}
        />
        <Route
          key="checkout"
          name={t('titles.checkout')}
          path="/:property/:subproperty/checkout"
          exact
          component={Views.CheckoutPage}
        />
        <Route
          key="success"
          name={t('titles.success')}
          path="/:property/:subproperty/success"
          exact
          component={Views.SuccessPage}
        />
      </Switch>
    );
  } else if (unknownProperty) {
    routes = (
      <Switch>
        <Route
          key="unknown-property"
          name={t('titles.unknownProperty')}
          path="/"
          component={Views.UnknownPropertyPage}
        />
      </Switch>
    );
  } else if (loading) {
    routes = <Loading />;
  } else {
    routes = (
      <Switch>
        <Route
          key="splash"
          name={t('titles.splash')}
          path="/"
          component={Views.SplashPage}
        />
      </Switch>
    );
  }

  return (
    <ConnectedRouter key="router" history={history}>
      <ScrollToTop history={history} />
      {!isWidget && property.whatsapp && (
        <WhastsappFabButton
          whatsapp={property.whatsapp}
          message="Tenho dúvidas sobre a reserva online."
        />
      )}
      {routes}
    </ConnectedRouter>
  );
}

export default App;
