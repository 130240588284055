import * as ActionTypes from './action-types';

export const setProducts = ads => ({
  type: ActionTypes.SET_PRODUCTS,
  payload: {
    ready: true,
    ads,
  },
});

export const setProductsCleanup = () => ({
  type: ActionTypes.SET_PRODUCTS_CLEANUP,
  payload: {
    ready: false,
    ads: [],
  },
});
