import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faUtensils, faCoffee } from '@fortawesome/free-solid-svg-icons';
import LangHelper from '../../../../helpers/lang-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RoomBoardType = ({boardType}) => {
  const { t } = useTranslation();

  let mealIcon = [3,4,5].includes(+boardType) ? faUtensils : ([2].includes(+boardType) ? faCoffee : '');
  let boarTypeLabel = t(LangHelper.getBoardTypeLangKey(boardType));

  // noinspection JSValidateTypes
  return boarTypeLabel === '' ? '' : (
    <span style={{ color: 'rgb(0, 128, 9)'}}>
        {mealIcon && (<FontAwesomeIcon icon={mealIcon} className='mr-2'/>)} 
        <b>{boarTypeLabel} </b>
        {/* {boardPrice!=='' ? ` - ${NumberHelper.currencyFormatted(boardPrice)} ${t('labels.breakfastObs')}` : ''} */}
    </span>
  );
};

RoomBoardType.propTypes = {
  boardType: PropTypes.number,
};

export default RoomBoardType;
