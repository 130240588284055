export const SERVICE_PROPERTY_CONNECT = {
  endpoint: '/connect',
  method: 'get',
  hostel: false,
};

export const SERVICE_PROPERTY = {
  endpoint: '/property',
  method: 'get',
  hostel: true,
};
