import * as ActionTypes from './action-types';
import ObjectHelper from '../../helpers/object-helper';
import NumberHelper from '../../helpers/number-helper';

/**
 * 
 */
const initialState = {
  loaded: false,
  ready: false,
  arrivalDate: null,
  departureDate: null,
  totalNights: 0,
  totalAdults: 0,
  totalChildren: 0,
  children: [],
  rooms: [],
  products: [],
  summary: {
    rooms: [],
    products: [],
  },
  couponCode: '',
  couponIsAccumulative: false,
  percentageDiscount: 0,
  installmentRate: 0,
  installmentRateAmount: 0,
  depositPercentage: 0,
  depositPercentageAmount: 0,
  valueDiscount: 0,
  totalAmount: 0,
  subtotalAmount: 0,
  additionalTax: 0,
  additionalTaxAmount: 0,
  cleaningTax: 0,
  productsAmount: 0,
  identification: {
    name: '',
    surname: '',
    document: '',
    birthDate: '',
    email: '',
    emailConfirmation: '',
    phoneNumber: '',
    country: '',
    notes: '',
    acceptedTerms: false,
  },
  payment: {
    creditCard: {
      use: false,
      name: '',
      number: '',
      validity: '',
      cvc: '',
      installment: 1,
      installmentRate: 0,
    },
    transfer: {
      use: false,
    },
    pix: {
      use: false,
    },
  },
  priceVariators: null,
  utmSource: null,
  askSuiteId: null
};

export default function reduce(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ActionTypes.SET_RESERVATION: {
      return { ...state, ...payload };
    }

    case ActionTypes.SET_RESERVATION_CLEANUP: {
      return { ...initialState };
    }

    case ActionTypes.SET_CHECKOUT_SUMMARY: {
      const installmentRate = NumberHelper.toFloat(payload.installmentRate);
      let depositPercentage = NumberHelper.toFloat(payload.depositPercentage);
      const rooms = [...state.rooms.filter(room => room.guests.length > 0)];
      const products = [
        ...state.products.filter(product => +product.total > 0),
      ];
      const { additionalTax } = state;

      // region Rooms

      const checkoutRooms = [];

      let subtotalAmount = 0;
      let totalAmount = 0;
      let productsAmount = 0;
      let additionalTaxAmount = 0;
      let cleaningTax = 0;
      let auxCleaningTax = 0;

      rooms.forEach(room => {

        productsAmount = 0;
        auxCleaningTax = 0;

        const checkoutRoom = {
          id: room.id,
          typeId: room.typeId,
          name: room.name,
          ratePlanTitle: room.ratePlanTitle,
          totalGuests: room.guests.length,
          totalRoomRequests: room.guests.length,
          realTotalGuests: 0,
          ratePlanBoardType: 0,
          totalPrice: 0,
          subtotalPrice: 0,
          cleaningTax: 0,
          auxCleaningTax: 0,
          ratePlanRealId: room.ratePlanRealId,
          roomTypeRealId: room.roomTypeRealId,
          adults: room.adults,
          children: room.children,
          childrenAdditional: room.childrenAdditional,
          childrenRegular: room.childrenRegular,
        };

        room.guests.forEach(guest => {
          checkoutRoom.cleaningTax = guest.cleaningTax;
          checkoutRoom.ratePlanBoardType = guest.ratePlanBoardType;
          checkoutRoom.totalPrice += guest.salesBasePrice;
          checkoutRoom.subtotalPrice += guest.salesBaseOriginalPrice;
          checkoutRoom.realTotalGuests += room.privateRoom
            ? +guest.salesBase
            : 1;
        });

        if (checkoutRoom.cleaningTax > 0) {
          auxCleaningTax +=
            checkoutRoom.cleaningTax * checkoutRoom.totalRoomRequests;
        }

        if (additionalTax > 0) {
          additionalTaxAmount +=
            (checkoutRoom.totalPrice * additionalTax) / 100;
        }

        checkoutRooms.push(checkoutRoom);

        totalAmount +=
          checkoutRoom.totalPrice + auxCleaningTax;
        cleaningTax += auxCleaningTax;
        subtotalAmount += checkoutRoom.subtotalPrice;
      });

      totalAmount += additionalTaxAmount;
      subtotalAmount += additionalTaxAmount;
      // endregion

      // region Products
      const checkoutProducts = [];

      products.forEach(product => {
        const checkoutProduct = {
          id: product.id,
          name: product.name,
          total: product.total,
          totalAmount: product.price * product.total,
          subtotalAmount: product.price * product.total,
        };

        totalAmount += checkoutProduct.totalAmount;
        productsAmount += checkoutProduct.totalAmount;

        checkoutProducts.push(checkoutProduct);
      });
      // endregion

      if (depositPercentage <= 0) {
        // eslint-disable-next-line prefer-destructuring
        depositPercentage = state.depositPercentage;
      }

      let installmentRateAmount = 0;
      let depositPercentageAmount = 0;

      if (depositPercentage > 0.0) {
        depositPercentageAmount = (totalAmount * depositPercentage) / 100;

        if (installmentRate > 0.0) {
          installmentRateAmount =
            (depositPercentageAmount * installmentRate) / 100;

          totalAmount += installmentRateAmount;
          depositPercentageAmount += installmentRateAmount;
        }
      }

      return {
        ...state,
        summary: { rooms: checkoutRooms, products: checkoutProducts },
        totalAmount,
        subtotalAmount,
        productsAmount,
        additionalTax,
        additionalTaxAmount,
        cleaningTax,
        installmentRate,
        installmentRateAmount,
        depositPercentage,
        depositPercentageAmount,
      };
    }

    case ActionTypes.SET_UPDATE_CHECKOUT_SUMMARY: {
      const rooms = [];
      const baseRooms = [...state.summary.rooms];
      const availabilityRooms = [...payload.availabilityRooms];

      baseRooms.forEach(room => {
        const realRoom = availabilityRooms.find(
          r => r.id === room.id && r.typeId === room.typeId
        );

        if (ObjectHelper.isObject(realRoom)) {
          rooms.push({
            ...room,
            totalPrice: realRoom.price,
            subtotalPrice: realRoom.originalPrice,
          });
        }
      });

      const updatedRequestedRooms = [];
      const requestedRooms = [...state.rooms];

      requestedRooms.forEach(room => {
        const realRoom = availabilityRooms.find(
          r => r.id === room.id && r.typeId === room.typeId
        );

        if (ObjectHelper.isObject(realRoom)) {
          let updatedGuests = [];

          const { guests } = room;

          if (guests.length > 0) {
            updatedGuests = guests.map(guest => {
              const salesBase = realRoom.salesBaseRooms.find(
                sbr => guest.salesBase === sbr.base
              );

              return {
                ...guest,
                salesBasePrice: salesBase.unitPrice,
                salesBaseOriginalPrice: salesBase.originalUnitPrice,
              };
            });
          }

          updatedRequestedRooms.push({ ...room, guests: [...updatedGuests] });
        }
      });

      return {
        ...state,
        rooms: [...updatedRequestedRooms],
        summary: { ...state.summary, rooms: [...rooms] },
      };
    }

    case ActionTypes.SET_GUEST: {

      const rooms = [...state.rooms];

      const currentRoom = rooms.find(room => room.id === payload.id);

      if (ObjectHelper.isObject(currentRoom)) {
        currentRoom.guests = currentRoom.guests.filter(
          guest => guest.salesBaseIndex !== payload.salesBaseIndex
        );

        if (!Array.isArray(currentRoom.guests)) {
          currentRoom.guests = [];
        }

        if (payload.totalGuests > 0) {
          for (let i = 1; i <= payload.totalGuests; i++) {
            currentRoom.guests.push({
              salesBaseIndex: payload.salesBaseIndex,
              salesBase: payload.salesBase,
              salesBasePrice: payload.salesBasePrice,
              salesBaseOriginalPrice: payload.salesBaseOriginalPrice,
              cleaningTax: payload.cleaningTax,
              ratePlanBoardType: payload.ratePlanBoardType,
            });
          }
        }

        const realRooms = [
          ...rooms.filter(r => r.id !== payload.id),
          currentRoom,
        ]
          .sort((a, b) => (a.index < b.index ? -1 : a.index > b.index ? 1 : 0))
          .map(a => a);

        return {
          ...state,
          rooms: realRooms,
        };
      }

      return { ...state };
    }

    case ActionTypes.SET_COUPON: {
      return {
        ...state,
        couponCode: payload.code,
        couponIsAccumulative: payload.isAccumulative === '1',
        percentageDiscount: payload.percentageDiscount,
        valueDiscount: payload.valueDiscount,
      };
    }

    case ActionTypes.SET_PRICE_VARIATORS: {
      return {
        ...state,
        priceVariators: payload.priceVariators,
      };
    }

    case ActionTypes.SET_PRODUCT: {
      const currentProduct = state.products.find(
        product => product.id === payload.id
      );

      if (ObjectHelper.isObject(currentProduct)) {
        currentProduct.total = payload.total;
      }

      const products = state.products.filter(
        product => +product.id !== +payload.id
      );

      return { ...state, products: [...products, ...[currentProduct]] };
    }

    case 'SERVICE_RESERVATION_LOADED': {
      return {
        ...state,
        loaded: payload,
      };
    }

    case ActionTypes.SET_IDENTIFICATION: {
      return {
        ...state,
        identification: { ...state.identification, ...payload },
      };
    }

    case ActionTypes.SET_PAYMENT: {
      return {
        ...state,
        payment: { ...state.payment, ...payload },
      };
    }

    case ActionTypes.SET_PAYMENT_CREDIT_CARD: {
      const currentPayment = { ...state.payment };

      currentPayment.creditCard.use = true;
      currentPayment.transfer.use = false;
      currentPayment.pix.use = false;

      return {
        ...state,
        payment: { ...state.payment, ...currentPayment },
      };
    }

    case ActionTypes.SET_PAYMENT_PIX: {
      const currentPayment = { ...state.payment };

      currentPayment.creditCard.use = false;
      currentPayment.transfer.use = false;
      currentPayment.pix.use = true;

      return {
        ...state,
        payment: { ...state.payment, ...currentPayment },
      };
    }

    case ActionTypes.SET_PAYMENT_BANK_TRANSFER: {
      const currentPayment = { ...state.payment };

      currentPayment.transfer.use = true;
      currentPayment.pix.use = false;
      currentPayment.creditCard.use = false;
      currentPayment.creditCard.name = '';
      currentPayment.creditCard.number = '';
      currentPayment.creditCard.validity = '';
      currentPayment.creditCard.cvc = '';
      currentPayment.creditCard.installment = 1;

      return {
        ...state,
        payment: { ...state.payment, ...currentPayment },
      };
    }

    case ActionTypes.SET_PAYMENT_CREDIT_CARD_DATA: {
      const currentPayment = { ...state.payment };

      currentPayment.creditCard = { ...currentPayment.creditCard, ...payload };

      return {
        ...state,
        payment: { ...state.payment, ...currentPayment },
      };
    }

    case ActionTypes.SET_UTM_SOURCE: {
      return {
        ...state,
        utmSource: payload.utmSource,
      };
    }

    case ActionTypes.SET_ASKSUITE_ID: {
      return {
        ...state,
        askSuiteId: payload.askSuiteId,
      };
    }

    default: {
      return state;
    }
  }
}
