import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import application from './application/reducer';
import property from './property/reducer';
import countries from './countries/reducer';
import currencies from './currencies/reducer';
import availability from './availability/reducer';
import travel from './travel/reducer';
import products from './products/reducer';
import reservation from './reservation/reducer';

export default history =>
  combineReducers({
    application,
    property,
    countries,
    currencies,
    availability,
    travel,
    products,
    reservation,
    router: connectRouter(history),
  });
