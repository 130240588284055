import { setCurrencies, setCurrentCurrency } from './actions';
import { http } from '../../helpers/http';
import * as CurrenciesService from '../../services/Currencies';
import CurrenciesHelper from '../../helpers/currencies-helper';

/**
 * @returns {Function}
 */
export const setCurrency = code => {
  return async (dispatch, getState) => {
    const {
      application: { ready },
      property: { countryCurrencyCode },
    } = getState();

    if (!ready) {
      return;
    }

    /**
     * @property {Boolean} success
     * @property {Object} data
     * @property {Number} data.conversionRate
     */
    const [success, data] = await http.request(
      CurrenciesService.SERVICE_CURRENCY_CONVERSION_RATE,
      {
        from: countryCurrencyCode,
        to: code,
      }
    );

    if (success) {
      const rate = data.conversionRate || 1;
      setTimeout(() => {
        dispatch(setCurrentCurrency(code, rate));
      }, 500);
    }
  };
};

/**
 * @returns {Function}
 */
export const loadCurrencies = (preSetedByURLCurrency = null) => {
  return async (dispatch, getState) => {
    const {
      application: {
        ready,
        property: { uid, subpropertyUid },
        i18n: { currentLanguage },
      },
      currencies: { ready: currenciesReady, current: currentCurrency },
      property: { countryCurrencyCode },
    } = getState();

    if (!ready || currenciesReady) {
      return;
    }

    /**
     * @property {Boolean} success
     * @property {Object} data
     * @property {Array.<{name: String, code: String, symbol: String, most_used: Boolean}>} data.currencies
     */
    const [success, data] = await http.request(
      CurrenciesService.SERVICE_CURRENCIES,
      {
        l: currentLanguage.toString().toLowerCase(),
        h: uid,
        s: subpropertyUid,
      }
    );

    if (success) {
      const currencies = data.currencies
        .sort((a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0))
        .map(currency => {
          return {
            name: currency.name,
            code: currency.code,
            symbol: currency.symbol,
            mostUsed: currency.most_used,
          };
        });

      dispatch(setCurrencies(currencies));

      const current = { ...currentCurrency };
      if (!current || current.code === null) {
        current.code = CurrenciesHelper.findDefault(countryCurrencyCode);
      }

      if (preSetedByURLCurrency) {
        dispatch(setCurrency(preSetedByURLCurrency));
      } else {
        dispatch(setCurrency(current.code));
      }
    }
  };
};
