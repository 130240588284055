import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ChildrenAges = props => {
  const { t } = useTranslation();
  const {
    currentChild,
    minimunChildrenAge,
    childAge,
    onChildAgeChange,
  } = props;
  const childIndex = currentChild + 1;

  // region Helpers

  const ageLabel = age => {
    if (age === 0) {
      return t('guests.upToOneYear');
    }

    if (age === 1) {
      return t('guests.oneYear');
    }

    return t('guests.moreYears', { years: age });
  };

  // endregion

  // region Events Handlers

  const onAgeChangeHandler = event => {
    onChildAgeChange(+event.target.value, currentChild);
  };

  // endregion

  const options = [...Array(18).keys()]
    .filter(item => item >= minimunChildrenAge)
    .map(item => (
      <option key={`age-${item}`} value={item}>
        {ageLabel(item)}
      </option>
    ));

  return (
    <div key={`child-${childIndex}`} className="row mb-3">
      <div className="col-6">
        <h5 className="mb-0">{t('guests.childAge', { age: childIndex })}</h5>
        <span className="small textGreyLight">{t('guests.endOfTrip')}</span>
      </div>
      <div className="col-6">
        <div className="form-group line-light bmd-form-group mb-0 pt-0">
          <select
            className="form-control gray py-0"
            value={childAge}
            onChange={onAgeChangeHandler}
          >
            {options}
          </select>
        </div>
      </div>
    </div>
  );
};

ChildrenAges.propTypes = {
  currentChild: PropTypes.number.isRequired,
  minimunChildrenAge: PropTypes.number.isRequired,
  childAge: PropTypes.number.isRequired,
  onChildAgeChange: PropTypes.func.isRequired,
};

export default ChildrenAges;
