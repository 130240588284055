const MaskHelper = {
  date: format => {
    let mask = null;

    if (format === 'DD/MM/YYYY') {
      mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    } else if (format === 'DD-MM-YYYY') {
      mask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      mask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
    }

    return mask;
  },
};

export default MaskHelper;
