import Payment from 'payment';
import StringHelper from './string-helper';

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 6) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
  }

  return clearValue;
}

export function findCreditCardBrand(
  ccNumber,
  simple = true,
  include_sub_types = false
) {
  const pan = StringHelper.justNumbers(ccNumber);

  // VISA
  const visa_regex = /^4[\d]*$/;
  const vpreca_regex = /^428485[\d]*$/;
  const postepay_regex = /^(402360|402361|403035|417631|529948)*$/;
  const cartasi_regex = /^(432917|432930|453998)[\d]*$/;
  const entropay_regex = /^(406742|410162|431380|459061|533844|522093)[\d]*$/;
  const o2money_regex = /^(422793|475743)[\d]*$/;

  // MASTERCARD
  const mastercard_regex = /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[\d]*$/;
  const maestro_regex = /^(5[06789]|6)[\d]*$/;
  const kukuruza_regex = /^525477[\d]*$/;
  const yunacard_regex = /^541275[\d]*$/;

  // AMERICAN EXPRESS
  const amex_regex = /^3[47][\d]*$/;

  // DINERS CLUB
  const diners_regex = /^3(?:0[0-59]|[689])[\d]*$/;

  // DISCOVER
  const discover_regex = /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[\d]*$/;

  // JCB
  const jcb_regex = /^(?:2131|1800|35)[\d]*$/;

  const normalizeCreditCardBrand = (brand, s = true) => {
    if (s) {
      if (brand === 'visa' || brand === 'mastercard') {
        return brand;
      }

      return 'other';
    }

    return brand;
  };

  if (pan.match(jcb_regex)) {
    return normalizeCreditCardBrand('jcb', simple);
  }

  if (pan.match(amex_regex)) {
    return normalizeCreditCardBrand('amex', simple);
  }

  if (pan.match(diners_regex)) {
    return normalizeCreditCardBrand('diners_club', simple);
  }

  if (include_sub_types) {
    if (pan.match(vpreca_regex)) {
      return normalizeCreditCardBrand('v-preca', simple);
    }

    if (pan.match(postepay_regex)) {
      return normalizeCreditCardBrand('postepay', simple);
    }

    if (pan.match(cartasi_regex)) {
      return normalizeCreditCardBrand('cartasi', simple);
    }

    if (pan.match(entropay_regex)) {
      return normalizeCreditCardBrand('entropay', simple);
    }

    if (pan.match(o2money_regex)) {
      return normalizeCreditCardBrand('o2money', simple);
    }

    if (pan.match(kukuruza_regex)) {
      return normalizeCreditCardBrand('kukuruza', simple);
    }

    if (pan.match(yunacard_regex)) {
      return normalizeCreditCardBrand('yunacard', simple);
    }
  }

  if (pan.match(visa_regex)) {
    return normalizeCreditCardBrand('visa', simple);
  }

  if (pan.match(mastercard_regex)) {
    return normalizeCreditCardBrand('mastercard', simple);
  }

  if (pan.match(discover_regex)) {
    return normalizeCreditCardBrand('discover', simple);
  }

  if (pan.match(maestro_regex)) {
    return normalizeCreditCardBrand('maestro', simple);
  }

  return 'other';
}
