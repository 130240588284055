import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import Cards from 'react-credit-cards';
import * as CreditCardValidator from 'card-validator';
import Header from '../../components/Structure/Header';
import Footer from '../../components/Structure/Footer';
import MainContent from '../../components/Structure/Content/Main';
import Sidebar from '../../components/Structure/Sidebar';
import PageHelper from '../../helpers/page-helper';
import { STEP_AVAILABILITY, STEP_CHECKOUT } from '../../helpers/steps';
import { setCurrentStep } from '../../store/application/actions';
import FormInput from '../../components/Form/Input';
import FormSelect from '../../components/Form/Select';
import * as ReservationActions from '../../store/reservation/actions';
import * as ReservationThunks from '../../store/reservation/thunks';
import FormCheckbox from '../../components/Form/Checkbox';
import SpinnerOverlay from '../../components/SpinnerOverlay';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  findCreditCardBrand,
} from '../../helpers/credit-card';
import StringHelper from '../../helpers/string-helper';
import ToasterHelper from '../../helpers/toaster-helper';
import MaskHelper from '../../helpers/mask-helper';
import ObjectHelper from '../../helpers/object-helper';
import NumberHelper from '../../helpers/number-helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import DatalayerHelper from '../../helpers/datalayer-helper';
import TermsPopup from '../../components/TermsPopup';

const isEmail = require('isemail');

const CheckoutPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const property = useSelector(state => state.property);
  const {
    termsInfoText,
    config: {
      creditCardPaymentEnabled,
      bankTransferPaymentEnabled,
      paymentGatewayEnabled,
      paymentGatewayInstallmentsEnabled,
      paymentGatewayInstallments,
      paymentGatewayPixEnabled,
    },
  } = property;
  const application = useSelector(state => state.application);
  const reservationLoaded = useSelector(state => state.reservation.loaded);
  const countries = useSelector(state => state.countries);
  const depositPercentage = useSelector(
    state => state.property.config.depositPercentage
  );
  const { i18n, currentStep, widget } = application;
  const { currentLanguage: language } = i18n;
  const availability = useSelector(state => state.availability);
  const { ready, rooms: availabilityRooms } = availability;
  const reservation = useSelector(state => state.reservation);
  const travel = useSelector(state => state.travel);
  const {
    totalNights,
    identification: resIdentification,
    payment: { creditCard: resPaymentCc },
    summary,
    coupon_code
  } = reservation;
  const {
    rooms: summaryRooms,
  } = summary;
  const [loaded, setLoaded] = useState(false);
  const [payByPix, setPayByPix] = useState(false);
  const [identification, setIdentification] = useState(resIdentification);
  const [creditCardPayment, setCreditCardPayment] = useState(resPaymentCc);
  const [creditCardCurrentFocus, setCreditCardCurrentFocus] = useState('');
  const [showTerms, setShowTerms] = useState(false);
  const creditCardPaymentDefault = {
    use: false,
    name: '',
    number: '',
    validity: '',
    cvc: '',
    installment: 1,
    installmentRate: 0,
  };

  const [installmentsOptions, setInstallmentsOptions] = useState(paymentGatewayInstallments.map(
    installment => {
      return {
        value: installment.value.toString(),
        label: installment.value.toString() + 'x',
      };
    }
  ));

  useEffect(() => {
    if(Array.isArray(summaryRooms) && summaryRooms.length !== 0){
      DatalayerHelper.sendBeginCheckoutEvent(property, coupon_code, totalNights, summaryRooms);
    }
  }, [property, coupon_code, totalNights, summaryRooms]);

  useEffect(() => {
    if (StringHelper.isNotEmpty(creditCardPayment.brand)) {
      setInstallmentsOptions(paymentGatewayInstallments.map(
        installment => {
          let label_interest_free = false;
          if (creditCardPayment.brand === 'visa' && NumberHelper.toFloat(installment.rate_visa) === 0.0) {
            label_interest_free = true;
          } else if (creditCardPayment.brand === 'mastercard' && NumberHelper.toFloat(installment.rate_mastercard) === 0.0) {
            label_interest_free = true;
          }
          else if (creditCardPayment.brand !== 'visa' && creditCardPayment.brand !== 'mastercard' && NumberHelper.toFloat(installment.rate) === 0.0) {
            label_interest_free = true;
          }
          return {
            value: installment.value.toString(),
            label: installment.value === 1 ? installment.label : (label_interest_free ? installment.label_interest_free : installment.label)
          };
        }
      ));
    }
  }, [creditCardPayment, paymentGatewayInstallments]);

  useEffect(() => {
    if ((!ready || !property.ready || !availability.ready) && !loaded) {
      PageHelper.verifyInternalPages(history, location);
    }
  }, [ready, property.ready, availability.ready, loaded, history, location]);

  if ((!ready || !property.ready || !availability.ready) && !loaded) {
    return null;
  }

  if (!loaded) {
    setLoaded(true);

    dispatch(
      ReservationActions.setCheckoutSummary(
        totalNights,
        creditCardPayment.installmentRate,
        depositPercentage
      )
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (currentStep !== STEP_CHECKOUT) {
      dispatch(setCurrentStep(STEP_CHECKOUT, true));
    }
  });

  // region Validations
  const applyValidations = () => {
    if (StringHelper.isEmpty(identification.name)) {
      ToasterHelper.error(t('errors.emptyField', { field: t('labels.name') }));
      return false;
    }

    if (StringHelper.isEmpty(identification.surname)) {
      ToasterHelper.error(
        t('errors.emptyField', { field: t('labels.surname') })
      );
      return false;
    }

    if (StringHelper.isEmpty(identification.document)) {
      ToasterHelper.error(
        t('errors.emptyField', { field: t('labels.document') })
      );
      return false;
    }

    if (StringHelper.isEmpty(identification.birthDate)) {
      ToasterHelper.error(
        t('errors.emptyField', { field: t('labels.birthDate') })
      );
      return false;
    }

    if (StringHelper.isEmpty(identification.email)) {
      ToasterHelper.error(t('errors.emptyField', { field: t('labels.email') }));
      return false;
    }

    if (!isEmail.validate(identification.email)) {
      ToasterHelper.error(t('errors.emailField', { field: t('labels.email') }));
      return false;
    }

    if (identification.email !== identification.emailConfirmation) {
      ToasterHelper.error(
        t('errors.equalField', {
          field: t('labels.email'),
          compareField: t('labels.emailConfirmation'),
        })
      );
      return false;
    }

    if (StringHelper.isEmpty(identification.phoneNumber)) {
      ToasterHelper.error(t('errors.emptyField', { field: t('labels.phone') }));
      return false;
    }

    if (StringHelper.isEmpty(identification.country)) {
      ToasterHelper.error(
        t('errors.emptyField', { field: t('labels.country') })
      );
      return false;
    }

    if (reservation.payment.creditCard.use) {
      if (StringHelper.isEmpty(resPaymentCc.name)) {
        ToasterHelper.error(
          t('errors.emptyField', { field: t('labels.name') })
        );
        return false;
      }

      if (StringHelper.isEmpty(resPaymentCc.number)) {
        ToasterHelper.error(
          t('errors.emptyField', { field: t('labels.creditCardNumber') })
        );
        return false;
      }

      const dt = String(resPaymentCc.validity).split('/');

      if (!(+dt[0] >= 0 && +dt[0] <= 12 && String(dt[1]).length === 4)) {
        ToasterHelper.error(t('errors.creditCardExpiry'));
        return false;
      }

      if (StringHelper.isEmpty(resPaymentCc.cvc)) {
        ToasterHelper.error(
          t('errors.emptyField', { field: t('labels.creditCardCvc') })
        );
        return false;
      }

      if (!CreditCardValidator.number(resPaymentCc.number).isValid) {
        ToasterHelper.error(t('errors.creditCardNumber'));
        return false;
      }

      if (!CreditCardValidator.cvv(resPaymentCc.cvc, creditCardPayment.cvcValidLength).isValid) {
        ToasterHelper.error(t('errors.creditCardCvc'));
        return false;
      }
    }

    if (!identification.acceptedTerms) {
      ToasterHelper.error(t('errors.acceptTerms'));
      return false;
    }

    return true;
  };
  // endregion

  // region Events Handler
  const onUpdateInformations = (
    creditCardPaymentData = creditCardPayment,
    identificationData = identification
  ) => {
    let identificationChanged = false;
    let paymentChanged = false;

    if (!identificationChanged) {
      Object.keys(identification).forEach(key => {
        if (
          identificationData.hasOwnProperty(key) &&
          resIdentification.hasOwnProperty(key) &&
          identificationData[key] !== resIdentification[key]
        ) {
          identificationChanged = true;
        }
      });
    }

    if (!paymentChanged) {
      Object.keys(creditCardPaymentData).forEach(key => {
        if (
          creditCardPaymentData.hasOwnProperty(key) &&
          resPaymentCc.hasOwnProperty(key) &&
          creditCardPaymentData[key] !== resPaymentCc[key]
        ) {
          paymentChanged = true;
        }
      });
    }

    if (identificationChanged) {
      dispatch(ReservationActions.setIdentification(identificationData));
    }

    if (paymentChanged) {
      dispatch(
        ReservationActions.setCheckoutSummary(
          totalNights,
          /* Preventing showing installmentsRate addition. InstallmentRate is added only if installment is bigger than 1 */
          creditCardPaymentData.installment > 1
            ? creditCardPaymentData.installmentRate
            : 0,
          depositPercentage
        )
      );

      dispatch(
        ReservationActions.setPaymentCreditCardData(creditCardPaymentData)
      );
    }
  };

  const onIdentificationChange = (value, attr) => {
    const changed = {};
    changed[attr] = value;
    setIdentification({ ...identification, ...changed });
    if (value === true) {
      setShowTerms(true);
    }
  };

  const onCreditCardDataChange = (value, attr) => {
    let attrValue = value;

    const changed = {};

    if (attr === 'number') {
      attrValue = formatCreditCardNumber(attrValue);
    } else if (attr === 'validity') {
      attrValue = formatExpirationDate(attrValue);
    } else if (attr === 'cvc') {
      attrValue = formatCVC(attrValue);
    }

    if (attr === 'number' || attr === 'installment') {
      let currentNumber = null;

      if (attr === 'number') {
        currentNumber = attrValue;
        attrValue = +creditCardPayment.installment;
      } else {
        attrValue = +attrValue;
      }

      if (NumberHelper.toInt(attrValue) <= 0) {
        attrValue = 1;
      }

      const creditCardNumber =
        attr === 'number' ? currentNumber : creditCardPayment.number;

      const creditCardBrand = findCreditCardBrand(creditCardNumber);

      const realCreditCardBrand = findCreditCardBrand(creditCardNumber, false);
      
      changed.brand = realCreditCardBrand;
      changed.normalizedBrand = creditCardBrand;

      changed.cvcValidLength = realCreditCardBrand === 'amex' ? [3,4] : [3];

      if (paymentGatewayEnabled && paymentGatewayInstallmentsEnabled) {

        const realInstallment = paymentGatewayInstallments.find(
          installment => +installment.value === +attrValue
        );

        let rateProperty = 'rate';

        if (creditCardBrand === 'visa') {
          rateProperty = 'rate_visa';
        } else if (creditCardBrand === 'mastercard') {
          rateProperty = 'rate_mastercard';
        }

        changed.installmentRate = ObjectHelper.pod(
          realInstallment,
          rateProperty,
          0
        );

      } else {

        changed.installmentRate = 0;
      }

      if (attr === 'number') {
        attrValue = currentNumber;
      }
    }

    changed[attr] = attrValue;

    const creditCardChangedData = { ...creditCardPayment, ...changed };

    setCreditCardPayment(creditCardChangedData);

    onUpdateInformations(creditCardChangedData);
  };

  const clearCreditCardData = () => {
    setCreditCardPayment({ ...creditCardPayment, ...creditCardPaymentDefault });
  };

  const onSendReservationHandler = (creditCard, bankTransfer, payByPix) => {
    const debug = false;

    if (debug) {
      console.log(creditCard, bankTransfer, payByPix);
    }

    onUpdateInformations();

    if (applyValidations()) {
      const realReservation = {
        ...reservation,
        ...{ identification },
      };

      dispatch(
        ReservationThunks.sendReservation(
          realReservation,
          availabilityRooms,
          travel,
          history,
          t
        )
      );
    }
  };
  // endregion

  // region Payment Details
  let creditCardButtonClasses = 'payment-tabs nav-link as-link';
  let creditCardContentClasses = 'tab-pane';
  let bankTransferButtonClasses = 'payment-tabs nav-link as-link';
  let bankTransferContentClasses = 'tab-pane';
  let pixButtonClasses = 'payment-tabs nav-link as-link';
  let pixContentClasses = 'tab-pane';
  let creditCardEnabled = false;
  let bankTransferEnabled = false;
  let pixEnabled = false;
  let creditCardActive = false;
  let bankTransferActive = false;
  let pixActive = false;

  if (creditCardPaymentEnabled) {
    creditCardEnabled = true;
  }
  if (paymentGatewayPixEnabled) {
    pixEnabled = true;
  }
  if (bankTransferPaymentEnabled) {
    bankTransferEnabled = true;
  }

  if (
    !reservation.payment.creditCard.use &&
    !reservation.payment.transfer.use &&
    !reservation.payment.pix.use
  ) {
    if (creditCardEnabled) {
      creditCardActive = true;
      dispatch(ReservationActions.setPaymentCreditCard(true));
      setPayByPix(false);
    } else if (bankTransferEnabled) {
      bankTransferActive = true;
      dispatch(ReservationActions.setPaymentBankTransfer(true));
      setPayByPix(false);
    } else if (pixEnabled) {
      pixActive = true;
      dispatch(ReservationActions.setPaymentPix(true));
      setPayByPix(true);
    }
  } else {
    creditCardActive = creditCardEnabled && reservation.payment.creditCard.use;
    bankTransferActive = bankTransferEnabled && reservation.payment.transfer.use;
    pixActive = pixEnabled && reservation.payment.pix.use;
  }

  if (creditCardActive) {
    creditCardButtonClasses += ' active';
    creditCardContentClasses += '  in active';
  } else if (pixActive) {
    pixButtonClasses += ' active';
    pixContentClasses += ' in active';
  } else if (bankTransferActive) {
    bankTransferButtonClasses += ' active';
    bankTransferContentClasses += ' in active';
  }
  // endregion

  // region Locale Details
  const locale = {
    number: t('labels.creditCardNumber').toLowerCase(),
    name: t('labels.name').toLowerCase(),
    valid: t('labels.creditCardValidity').toLowerCase(),
    cvc: t('labels.creditCardCvc').toLowerCase(),
  };

  const placeholders = {
    number: t('labels.creditCardNumber'),
    name: t('labels.name'),
    valid: t('labels.creditCardValidity'),
    cvc: t('labels.creditCardCvc'),
  };
  // endregion

  const sidebar = (
    <Sidebar
      property={property}
      reservation={reservation}
      page={STEP_AVAILABILITY}
      canSendReservation={true}
      onSendReservation={() => {
        onSendReservationHandler(creditCardActive, bankTransferActive, payByPix);
      }}
      showAbout
      showCoupon
      showCheckoutSummary
    />
  );

  const paymentTypeTabWidthClasses =
    creditCardPaymentEnabled && bankTransferPaymentEnabled && pixEnabled
      ? ['nav-item ', 'col-4', 'm-0', 'p-0']
      : (((creditCardPaymentEnabled && bankTransferPaymentEnabled) || (creditCardPaymentEnabled && pixEnabled) || (bankTransferPaymentEnabled && pixEnabled)) ? ['nav-item ', 'w-50'] : ['nav-item ', 'w-100']);

  const isWidget = widget === '1';
  const widgetStyle = isWidget ? { marginTop: `-40px` } : {};

  return (
    <Fragment>
      <Header />
      <MainContent sidebar={sidebar}>
        <Fragment>
          <div className="card mt-n4 mb-4 checkout">
            <div className="card-body" style={widgetStyle}>
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-3">{t('titles.identification')}</h2>
                  <div>
                    <div className="form-row">
                      <div className="col-12 col-md-6 pl-0">
                        <FormInput
                          name="name"
                          id="identification-name"
                          label={`${t('labels.name')}(*)`}
                          value={identification.name}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          transparent
                        />
                      </div>
                      <div className="col-12 col-md-6 pl-0">
                        <FormInput
                          name="surname"
                          id="identification-surname"
                          label={`${t('labels.surname')}(*)`}
                          value={identification.surname}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          transparent
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 col-md-6 pl-0">
                        <FormInput
                          name="document"
                          id="identification-document"
                          label={`${t('labels.document')}(*)`}
                          value={identification.document}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          transparent
                        />
                      </div>
                      <div className="col-12 col-md-6 pl-0">
                        <FormInput
                          name="birthDate"
                          id="identification-birth-date"
                          label={`${t('labels.birthDate')}(*)`}
                          value={identification.birthDate}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          mask={MaskHelper.date(t('calendar.dateFormat'))}
                          masked
                          transparent
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 col-md-6 pl-0">
                        <FormInput
                          name="email"
                          id="identification-email"
                          label={`${t('labels.email')}(*)`}
                          value={identification.email}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          transparent
                        />
                      </div>
                      <div className="col-12 col-md-6 pl-0">
                        <FormInput
                          name="emailConfirmation"
                          id="identification-email-confirmation"
                          label={`${t('labels.emailConfirmation')}(*)`}
                          value={identification.emailConfirmation}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          transparent
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 col-md-6 pl-0">
                        <FormInput
                          name="phoneNumber"
                          id="identification-phone-number"
                          label={`${t('labels.phone')}(*)`}
                          value={identification.phoneNumber}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          transparent
                        />
                      </div>
                      <div className="col-12 col-md-6 pl-0">
                        <FormSelect
                          name="country"
                          id="identification-country"
                          label={`${t('labels.country')}(*)`}
                          value={identification.country}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          options={countries.options.map(country => {
                            return { value: country.id, label: country.name };
                          })}
                          prompt
                          transparent
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12 col-md-12">
                        <FormInput
                          name="notes"
                          id="identification-notes"
                          label={t('labels.notes')}
                          value={identification.notes}
                          onInputChange={onIdentificationChange}
                          onInputRealChange={onUpdateInformations}
                          onInputFocus={() => {
                            setCreditCardCurrentFocus('');
                          }}
                          transparent
                          textarea
                        />
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-12">

                        <h3 className="mb-3">{t('titles.payment')}</h3>

                        <ul className="nav nav-tabs" role="tablist">

                          {creditCardPaymentEnabled && (
                            <li
                              className={paymentTypeTabWidthClasses.join(' ')}
                            >
                              <button
                                className={creditCardButtonClasses}
                                role="tab"
                                data-toggle="tab"
                                onClick={() => {
                                  setPayByPix(false);
                                  dispatch(
                                    ReservationActions.setPaymentCreditCard(
                                      true
                                    )
                                  );
                                }}
                              >
                                {t('payment.creditCard')}
                              </button>
                            </li>
                          )}

                          {paymentGatewayPixEnabled && (
                            <li
                              className={paymentTypeTabWidthClasses.join(' ')}
                            >
                              <button
                                className={pixButtonClasses}
                                role="tab"
                                data-toggle="tab"
                                onClick={() => {
                                  setPayByPix(true);
                                  clearCreditCardData();
                                  dispatch(
                                    ReservationActions.setPaymentPix(
                                      true
                                    )
                                  );
                                }}
                              >
                                PIX
                              </button>
                            </li>
                          )}

                          {bankTransferPaymentEnabled && (
                            <li
                              className={paymentTypeTabWidthClasses.join(' ')}
                            >
                              <button
                                className={bankTransferButtonClasses}
                                role="tab"
                                data-toggle="tab"
                                onClick={() => {
                                  setPayByPix(false);
                                  clearCreditCardData();
                                  dispatch(
                                    ReservationActions.setPaymentBankTransfer(
                                      true
                                    )
                                  );
                                }}
                              >
                                {paymentGatewayPixEnabled ? t('payment.bankTransfer') : t('payment.transferOrPIX')}
                              </button>
                            </li>
                          )}
                        </ul>

                        <div className="tab-content mt-5 mb-3">
                          {creditCardPaymentEnabled && (
                            <div
                              role="tabpanel"
                              className={creditCardContentClasses}
                            >
                              <div className="form-row">
                                <div className="col-12 p-0">
                                  <Cards
                                    name={creditCardPayment.name}
                                    number={creditCardPayment.number}
                                    expiry={creditCardPayment.validity}
                                    cvc={creditCardPayment.cvc}
                                    focused={creditCardCurrentFocus}
                                    locale={locale}
                                    placeholders={placeholders}
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-12 col-md-6 p-0">
                                  <FormInput
                                    name="name"
                                    id="payment-cc-name"
                                    label={t('labels.name')}
                                    value={creditCardPayment.name}
                                    onInputChange={onCreditCardDataChange}
                                    onInputRealChange={onUpdateInformations}
                                    onInputFocus={() => {
                                      setCreditCardCurrentFocus('name');
                                    }}
                                    transparent
                                  />
                                </div>
                                <div className="col-12 offset-md-1 col-md-5 p-0">
                                  <FormInput
                                    name="number"
                                    id="payment-cc-number"
                                    label={t('labels.creditCardNumber')}
                                    value={creditCardPayment.number}
                                    onInputChange={onCreditCardDataChange}
                                    onInputRealChange={onUpdateInformations}
                                    onInputFocus={() => {
                                      setCreditCardCurrentFocus('number');
                                    }}
                                    transparent
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="col-12 col-md-2 p-0">
                                  <FormInput
                                    name="validity"
                                    id="payment-cc-validity"
                                    label={`XX/XXXX`}
                                    value={creditCardPayment.validity}
                                    onInputChange={onCreditCardDataChange}
                                    onInputRealChange={onUpdateInformations}
                                    onInputFocus={() => {
                                      setCreditCardCurrentFocus('expiry');
                                    }}
                                    transparent
                                  />
                                </div>
                                <div className="col-12 col-md-2 offset-md-5 p-0">
                                  <FormInput
                                    name="cvc"
                                    id="payment-cc-cvc"
                                    label={'CCV'}
                                    value={creditCardPayment.cvc}
                                    onInputChange={onCreditCardDataChange}
                                    onInputRealChange={onUpdateInformations}
                                    onInputFocus={() => {
                                      setCreditCardCurrentFocus('cvc');
                                    }}
                                    transparent
                                  />
                                </div>

                                {paymentGatewayEnabled &&
                                  paymentGatewayInstallmentsEnabled && (
                                    <div className="col-12 col-md-6 pl-0">
                                      <FormSelect
                                        name="installment"
                                        id="payment-cc-installment"
                                        label={`${t('labels.installments')}(*)`}
                                        value={creditCardPayment.installment.toString()}
                                        onInputChange={onCreditCardDataChange}
                                        onInputRealChange={onUpdateInformations}
                                        onInputFocus={() => {
                                          setCreditCardCurrentFocus(
                                            'installment'
                                          );
                                        }}
                                        options={installmentsOptions}
                                        prompt
                                        transparent
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}

                          {bankTransferPaymentEnabled && (
                            <div
                              role="tabpanel"
                              className={bankTransferContentClasses}
                            >
                              <div className="row py-5">
                                <div className="col-12">
                                  <p>{t('payment.bankTransferMessage')}</p>
                                </div>
                              </div>
                            </div>
                          )}

                          {paymentGatewayPixEnabled && (
                            <div
                              role="tabpanel"
                              className={pixContentClasses}
                            >
                              <div className="row py-5">
                                <div className="col-12" style={{ textAlign: "center" }}>
                                  <FormCheckbox
                                    name="payByPix"
                                    id="payByPix"
                                    checked={payByPix}
                                    label={t('payment.pixMessage')}
                                    onInputChange={() => { }}
                                    onInputRealChange={() => { }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <br /><br />

                        <div className="row pt-3 mb-3">
                          <div className="col-12">
                            <div className="h6 mb-3">
                              <FontAwesomeIcon
                                className="mr-2"
                                style={{ color: 'rgb(138 133 133)', cursor: 'pointer' }}
                                onClick={() => setShowTerms(!showTerms)}
                                icon={faFileContract}
                              />
                              <span style={{ color: 'rgb(138 133 133)', cursor: 'pointer' }} onClick={() => setShowTerms(!showTerms)}>
                                {t('info.terms')}
                              </span>
                            </div>
                            <FormCheckbox
                              name="acceptedTerms"
                              id="identification-accepted-terms"
                              label={t('info.acceptTerms')}
                              checked={identification.acceptedTerms}
                              onInputChange={onIdentificationChange}
                              onInputRealChange={onUpdateInformations}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TermsPopup 
            language={language ?? ""}
            propertyTerms={termsInfoText ?? ""} 
            showTerms={showTerms} 
            setShowTerms={setShowTerms} 
            />

        </Fragment>
      </MainContent>
      <Footer />
      {!reservationLoaded && <SpinnerOverlay />}
    </Fragment>
  );
};

export default CheckoutPage;
