import * as ActionTypes from './action-types';
import CurrenciesHelper from '../../helpers/currencies-helper';
import NumberHelper from '../../helpers/number-helper';

const initialState = {
  ready: false,
  current: {
    name: null,
    code: null,
    symbol: null,
    mostUsed: false,
    rate: 1,
  },
  options: [],
};

export default function reduce(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ActionTypes.SET_CURRENT_CURRENCY: {
      if (!state.ready) {
        return { ...state };
      }

      const current = state.options.find(currency => {
        return currency.code === payload.code;
      });

      if (current && current.code) {
        CurrenciesHelper.save(current.code);

        NumberHelper.currencySymbol = current.symbol;
        NumberHelper.currencyCode = current.code;
        NumberHelper.toCurrencyRate = NumberHelper.toFloat(payload.rate);

        return { ...state, current: { ...current, rate: payload.rate } };
      }

      return { ...state };
    }

    case ActionTypes.SET_CURRENCIES: {
      return {
        ...state,
        ready: true,
        options: [...payload],
      };
    }

    default: {
      return state;
    }
  }
}
