import React from 'react';
import { useTranslation } from 'react-i18next';

const SpinnerOverlay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div id="cover-spin">
        <label>{t('labels.wait_your_reservation_is_being_processed')}</label>
      </div>
    </>
  );
};

export default SpinnerOverlay;
