import React from 'react';
import PropTypes from 'prop-types';
import StringHelper from '../../../helpers/string-helper';

const Photo = props => {
  const { photoUrl, onPhotoOpen, index } = props;
  let { label } = props;

  if (StringHelper.isEmpty(photoUrl)) {
    return null;
  }

  if (StringHelper.isEmpty(label)) {
    label = '';
  }

  return (
    <div className="as-link" onClick={() => onPhotoOpen(index)}>
      <div
        className="img rounded"
        style={{ backgroundImage: `url('${photoUrl}')` }}
      >
        {StringHelper.isNotEmpty(label) && (
          <div className="description p-2">
            <h3>{label}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

Photo.propTypes = {
  photoUrl: PropTypes.string.isRequired,
  label: PropTypes.string,
  onPhotoOpen: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default Photo;
