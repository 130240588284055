import moment from 'moment';
import StringHelper from './string-helper';

const DateTimeHelper = {
  isDate: date => {
    return date !== null && typeof date.format === 'function';
  },

  isUnformattedDate: date => {
    return (
      StringHelper.isNotEmpty(date) &&
      new RegExp(/^(\d{4})-(\d{2})-(\d{2})$/).test(date.toString())
    );
  },

  toDisplay: (date, t) => {
    if (DateTimeHelper.isUnformattedDate(date)) {
      return moment(date).format(t('calendar.dateFormat'));
    }

    return '';
  },

  formatDate: date => {
    if (date) {
      return moment(date, ['DD-MM-YYYY', 'DD/MM/YYYY'], true).isValid()
        ? moment(date, ['DD-MM-YYYY', 'DD/MM/YYYY']).format('YYYY-MM-DD')
        : date;
    }
    return date;
  },
};

export default DateTimeHelper;
