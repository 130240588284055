import * as ActionTypes from './action-types';

export const setProperty = property => ({
  type: ActionTypes.SET_PROPERTY,
  payload: { ...property },
});

export const setPropertyIsReady = () => ({
  type: ActionTypes.SET_PROPERTY_IS_READY,
  payload: {},
});
