import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StringHelper from '../../helpers/string-helper';
import ToasterHelper from '../../helpers/toaster-helper';

const ChooseButtons = props => {
  const mainColor = useSelector(state => state.property.config.mainColor);
  const { t } = useTranslation();
  const {
    type,
    className,
    total,
    max,
    maxReached,
    sumup,
    onTotalChange,
    buttonLabel,
    canSale,
    isRoomAlreadySelectedInAnotherPlan,
  } = props;

  const [totalSelected, setTotalSelected] = useState(0);
  const buttonSize = '2x';
  const holderClassName = StringHelper.isNotEmpty(className) ? `${className} num-increase` : 'num-increase';
  const maxItems = sumup ? max + totalSelected : max;
  const hasMaxItems = maxItems && maxItems > 0;

  if (totalSelected > total) {
    setTotalSelected(total);
  }

  // region Events

  const onAddHandler = () => {
    const ts = totalSelected + 1;
    if (maxReached || (hasMaxItems && ts > maxItems)) {
      return;
    }
    setTotalSelected(ts);
    onTotalChange('add', ts);
  };

  const onSubtractHandler = () => {
    if (totalSelected > 0) {
      const ts = totalSelected - 1;
      setTotalSelected(ts);
      onTotalChange('subtract', ts);
    }
  };
  // endregion

  if (total > 0 && totalSelected === 0) {
    setTotalSelected(total);
  }

  const notPeopleSelectorPopup = type!=='peopleNumberSelector';

  if(notPeopleSelectorPopup && (isRoomAlreadySelectedInAnotherPlan || (!canSale && isRoomAlreadySelectedInAnotherPlan))){
    return (
      <div className={holderClassName} style={{ minWidth: '120px' }}>
        <button
          type="button"
          className="btn btn-raised btn-disabled slim text-uppercase"
          onClick={() => ToasterHelper.error(t('errors.anotherRoomPlanSelected'))}
        >
          {t('labels.choose')}
        </button>
      </div>
    );
  }

  if(notPeopleSelectorPopup && !canSale){
    return '';
  }

  if(notPeopleSelectorPopup && totalSelected === 0) {
    return (
      <div className={holderClassName} style={{ minWidth: '120px' }}>
        <button
          type="button"
          className="btn btn-raised btn-orange slim text-uppercase"
          onClick={onAddHandler}
        >
          {StringHelper.isNotEmpty(buttonLabel)
            ? buttonLabel
            : t('labels.choose')}
        </button>
      </div>
    );
  }

  return (
    <div className={holderClassName} style={{ minWidth: '120px' }}>
      <FontAwesomeIcon
        className="button-drop-shadow buttonColor"
        icon={faMinusCircle}
        size={buttonSize}
        style={{ color: mainColor }}
        onClick={onSubtractHandler}
      />
      <span>{totalSelected.toString()}</span>
      <FontAwesomeIcon
        className="button-drop-shadow buttonColor"
        icon={faPlusCircle}
        size={buttonSize}
        style={{ color: mainColor }}
        onClick={onAddHandler}
      />
    </div>
  );
};

ChooseButtons.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  max: PropTypes.number,
  maxReached: PropTypes.bool,
  sumup: PropTypes.bool,
  onTotalChange: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  canSale: PropTypes.bool,
  isRoomAlreadySelectedInAnotherPlan: PropTypes.bool,
};

export default ChooseButtons;
