import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const SummaryStatus = ({ missingVacancy, adults }) => {

    const { t } = useTranslation();

    return (
        <div className="row p-2 pt-4">
            <div className="col-12 summary_status">
                <div style={{ display: 'flex', gap: '8px' }}>
                    <FontAwesomeIcon
                        className="button-drop-shadow buttonColor"
                        icon={faExclamationCircle}
                        size={'2x'}
                        style={{ color: '#c00' }}
                    />
                    <p className="textGreyLight" style={{ textAlign: 'left', fontWeight: '600', lineHeight: '1.4' }}>
                        {t('info.summaryVacancyAlert', { missingVacancy, adults })}
                    </p>
                </div>
            </div>
        </div>
    );
};


export default SummaryStatus;



