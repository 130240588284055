import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducers from './Reducers';

export const history = createBrowserHistory();

export const middleware = [thunk, routerMiddleware(history)];

export const store = createStore(
  reducers(history),
  composeWithDevTools(applyMiddleware(...middleware))
);
