import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PaymentMethods = props => {
  const { t } = useTranslation();
  const { creditCardPaymentEnabled, bankTransferPaymentEnabled } = props;

  let creditCard = null;
  let bankTransfer = null;

  if (creditCardPaymentEnabled) {
    creditCard = (
      <Fragment>
        <li className="list-inline-item">
          <FontAwesomeIcon icon={faCcVisa}>
            <span>{t('payment.visa')}</span>
          </FontAwesomeIcon>
        </li>
        <li className="list-inline-item">
          <FontAwesomeIcon icon={faCcMastercard}>
            <span>{t('payment.mastercard')}</span>
          </FontAwesomeIcon>
        </li>
      </Fragment>
    );
  }

  if (bankTransferPaymentEnabled) {
    bankTransfer = (
      <Fragment>
        <li className="list-inline-item">
          <FontAwesomeIcon icon={faRandom}>
            <span>{t('payment.bankTransfer')}</span>
          </FontAwesomeIcon>
        </li>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <ul className="credit-cards list-unstyled m-0 text-center text-lg-left">
        {creditCard}
        {bankTransfer}
      </ul>
    </Fragment>
  );
};

PaymentMethods.propTypes = {
  creditCardPaymentEnabled: PropTypes.bool.isRequired,
  bankTransferPaymentEnabled: PropTypes.bool.isRequired,
};

export default PaymentMethods;
