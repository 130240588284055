import * as ActionTypes from './action-types';

const initialState = {
  loaded: false,
  arrivalDate: null,
  departureDate: null,
  totalNights: 0,
  totalAdults: 0,
  totalChildren: 0,
  children: [],
  preSelectedRoom: null,
};

export default function reduce(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ActionTypes.SET_AS_EMPTY: {
      return { ...initialState };
    }

    case ActionTypes.SET_IS_LOADED: {
      return { ...state, loaded: payload.loaded };
    }

    case ActionTypes.SET_ARRIVAL_DATE: {
      let { loaded } = state;

      if (loaded && state.arrivalDate !== payload.date) {
        loaded = false;
      }

      return { ...state, loaded, arrivalDate: payload.date };
    }

    case ActionTypes.SET_DEPARTURE_DATE: {
      let { loaded } = state;

      if (loaded && state.departureDate !== payload.date) {
        loaded = false;
      }

      return { ...state, loaded, departureDate: payload.date };
    }

    case ActionTypes.SET_TOTAL_ADULTS: {
      let { loaded } = state;

      if (loaded && state.totalAdults !== +payload.adults) {
        loaded = false;
      }

      return { ...state, loaded, totalAdults: +payload.adults };
    }

    case ActionTypes.SET_TOTAL_NIGHTS: {
      let { loaded } = state;

      if (loaded && state.totalNights !== +payload.totalNights) {
        loaded = false;
      }

      return { ...state, loaded, totalNights: +payload.totalNights };
    }

    case ActionTypes.SET_TOTAL_CHILDREN: {
      const totalChildren = +payload.children;
      let children = [];

      if (state.children.length > 0) {
        if (state.children.length > totalChildren) {
          children = state.children.filter((child, index) => {
            return index < totalChildren;
          });
        } else if (state.children.length < totalChildren) {
          const diff = totalChildren - state.children.length;

          children = [...state.children];

          for (let i = 1; i <= diff; i++) {
            children.push(0);
          }
        } else {
          children = [...state.children];
        }
      } else {
        for (let i = 1; i <= totalChildren; i++) {
          children.push(0);
        }
      }

      return { ...state, loaded: false, totalChildren, children };
    }

    case ActionTypes.SET_CHILD_AGE: {
      const children = [...state.children];

      if (typeof children[payload.index] !== 'undefined') {
        children[payload.index] = payload.age;
      }

      return { ...state, loaded: false, children };
    }

    case ActionTypes.SET_PRE_SELECTED_ROOM: {
      return { ...state, preSelectedRoom: payload.preSelectedRoom };
    }

    case ActionTypes.SET_TRAVEL_CLEANUP: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}
