import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import NumberHelper from '../../../helpers/number-helper';
import ChooseButtons from '../../ChooseButtons';
import StringHelper from '../../../helpers/string-helper';
import { updateProduct } from '../../../store/reservation/thunks';

const Product = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { product, reservationProducts } = props;
  const { id, name, description, price, limit, photo } = product;

  let backgroundImage = {};
  let backgroundImageCss = 'product-image';

  if (StringHelper.isNotEmpty(photo)) {
    backgroundImage = { backgroundImage: `url(${photo})` };
    backgroundImageCss += ' fixed';
  }

  backgroundImageCss += '  img-responsive';

  const reservationProduct = reservationProducts.find(
    p => +p.id === +product.id
  );

  const { total: totalRequested } = reservationProduct;

  return (
    <div className="col-12 col-lg-6">
      <div className="card mb-3">
        <div className={backgroundImageCss} style={backgroundImage} />
        <div className="card-body">
          <h5 className="card-title prod-card-title" title={name}>
            {name}
          </h5>
          <p className="card-text text-justify" title={description}>
            {description}
          </p>
          <div className="row">
            <div className="col">
              <span className="currency mainClr font-weight-bolder">
                {NumberHelper.currencyFormatted(price)}
              </span>
            </div>
            <div className="col text-right">
              <ChooseButtons
                type="unitsSelector"
                buttonLabel={t('labels.add')}
                className="text-right"
                total={totalRequested}
                max={limit}
                onTotalChange={(operation, total) => {
                  dispatch(updateProduct(id, total));
                }}
                canSale={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  reservationProducts: PropTypes.array.isRequired,
};

export default Product;
