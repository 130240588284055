import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StringHelper from '../../helpers/string-helper';
import Amenity from './Amenity';

const Amenities = props => {
  const { t } = useTranslation();
  const { slug, amenities, holderClasses, footer } = props;

  if (!amenities || amenities.length <= 0) {
    return null;
  }

  let classes = 'amenity list-unstyled m-0';

  if (StringHelper.isNotEmpty(holderClasses)) {
    classes += ` ${holderClasses}`;
  }

  return (
    <Fragment>
      {footer && <h5 className="mb-3">{t('titles.amenities')}</h5>}

      <ul className={classes}>
        {amenities
          .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
          .map((amenity, index) => {
            const amenityKey = `${slug}-amenity-${index + 1}`;

            return (
              <li key={amenityKey} className="list-inline-item">
                <Amenity
                  slug={amenityKey}
                  label={amenity.label}
                  icon={amenity.icon}
                />
              </li>
            );
          })}
      </ul>
    </Fragment>
  );
};

Amenities.propTypes = {
  slug: PropTypes.string.isRequired,
  amenities: PropTypes.array.isRequired,
  holderClasses: PropTypes.string,
  footer: PropTypes.bool,
};

export default Amenities;
