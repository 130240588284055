import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StringHelper from '../../../helpers/string-helper';

const FormSelect = props => {
  const { t } = useTranslation();
  const {
    id,
    name,
    label,
    value,
    transparent,
    options,
    white,
    prompt,
    onInputChange,
    onInputRealChange,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const useWhite = false;

  let groupClasses = 'form-group line-light bmd-form-group mb-0 is-focused';
  let controlClasses = '';

  if (!isFocused && StringHelper.isNotEmpty(value)) {
    controlClasses = ' is-filled';
  }

  groupClasses += controlClasses;

  let inputClasses = 'form-control text-uppercase';

  if (useWhite && white) {
    inputClasses += ' text-white';
  }

  if (transparent) {
    inputClasses += ' bg-transparent';
  }

  let selectOptions = [...options];

  if (prompt) {
    const promptOptions = [{ value: '', label: t('labels.select') }];

    selectOptions = [...promptOptions, ...options];
  }

  return (
    <div className={groupClasses}>
      <label htmlFor={id} className="bmd-label-floating text-uppercase">
        {label}
      </label>
      <select
        id={id}
        name={name}
        className={inputClasses}
        value={value}
        onFocus={() => {
          setIsFocused(true);

          if (typeof onInputRealChange === 'function') {
            onInputRealChange();
          }
        }}
        onBlur={() => {
          setIsFocused(false);

          if (typeof onInputRealChange === 'function') {
            onInputRealChange();
          }
        }}
        onChange={event => {
          const v = event.target.value;
          const n = event.target.name;

          onInputChange(v, n, true);
        }}
      >
        {selectOptions.map(option => {
          return (
            <option key={`${id}-${option.value}`} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      <span className="msg-error">Por favor preencha o campo.</span>
    </div>
  );
};

FormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  transparent: PropTypes.bool,
  white: PropTypes.bool,
  prompt: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onInputRealChange: PropTypes.func.isRequired,
};

export default FormSelect;
