import React from 'react';

const RoomBlock = (props) => {

  return (
    <div className="card mb-4 room-info">
      <div className="card-body roomPlans">
        {props.children}
      </div>
    </div>
  );
};

export default RoomBlock;
