import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import StringHelper from '../../../helpers/string-helper';
import Amenities from '../../Amenities';
import RoomGallery from './Gallery';
import RoomBoardType from './BoardType';
import RoomSaleBase from './SaleBase';
import * as ReservationThunks from '../../../store/reservation/thunks';
import ObjectHelper from '../../../helpers/object-helper';
import NumberHelper from '../../../helpers/number-helper';
import DatalayerHelper from '../../../helpers/datalayer-helper';
import { ReadMore } from '../../ReadMore';

const Room = ({
  room,
  lastRoomTypeRealId,
  config,
  reservationRoom,
  totalGuests,
  reservationRooms,
  isRoomAlreadySelectedInAnotherPlan,
  autoSelect,
}) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const reservation = useSelector(state => state.reservation);
  const property = useSelector(state => state.property);

  if (!ObjectHelper.isObject(reservationRoom)) {
    return null;
  }

  const mainPhotoUrl = room.hasMainPhoto ? room.mainPhoto.url : null;
  const mainPhotoThumbnailUrl = room.hasMainPhoto ? room.mainPhoto.thumbnailUrl : null;

  // region Helpers
  const relatedRegisteredGuests = () => {
    const relatedRooms = reservationRooms.filter(
      relatedRoom =>
        +relatedRoom.id !== +room.id &&
        +relatedRoom.roomTypeRealId === +room.roomTypeRealId
    );

    let guests = 0;

    if (Array.isArray(relatedRooms) && relatedRooms.length > 0) {
      relatedRooms.forEach(relatedRoom => {
        guests += relatedRoom.guests.length;
      });
    }

    return guests;
  };
  // endregion

  const subtitle = (room.privateRoom) ? t('labels.private') : '';

  const { available } = room;

  const registeredGuests = relatedRegisteredGuests();

  let totalAvailable = available;
  totalAvailable = (registeredGuests > 0) ? ((totalAvailable - registeredGuests >= 0) ? totalAvailable - registeredGuests : 0) : available;
  totalAvailable = (config.reservationMaxGuests > 0 && room.available > config.reservationMaxGuests) ? config.reservationMaxGuests : totalAvailable;

  const totalSalesBaseItems = room.salesBaseRooms.length;

  const isNewRoomBlock = lastRoomTypeRealId !== room.roomTypeRealId;

  const planPricesSectionClass = (!isNewRoomBlock) ? 'row mt-4' : 'row';

  const onAddGuestClick = (operation, room, saleBase, totalRoomGuests, index) => {
    dispatch(
      ReservationThunks.addGuest(
        room.id,
        room.typeId,
        index,
        saleBase.base,
        saleBase.unitPrice,
        saleBase.originalUnitPrice,
        totalRoomGuests,
        reservation.totalNights,
        room.cleaningTax,
        room.ratePlanRealId,
        room.roomTypeRealId,
        room.ratePlanBoardType
      )
    );
    const guestsNumber = room.privateRoom === false ? 1 : saleBase.base;
    DatalayerHelper.handleCartEvent(
      operation,
      property,
      reservation,
      room.id,
      room.name,
      saleBase.unitPrice,
      guestsNumber,
      1,
      room.ratePlanTitle
    );
  }

  return (
    <section id={`block-room-${room.roomTypeRealId}`}>
      {isNewRoomBlock && (
        <div className='row mb-3'>
          <div className="col-12 col-md-4 mb-3">
            <RoomGallery
              mainPhotoUrl={mainPhotoUrl}
              mainPhotoThumbnailUrl={mainPhotoThumbnailUrl}
              photos={room.gallery}
            />
          </div>
          <div className="col-12 col-md-8">
            <div className="infos v-align">
              <div className="row p-0">
                <div className="col-12">
                  <h3>
                    {room.name}
                    <span className="small ml-3">{subtitle}</span>
                  </h3>

                  <div>
                    <Amenities
                      slug={`room-${room.id}`}
                      amenities={room.amenities}
                      holderClasses="pb-1"
                    />
                    {StringHelper.isNotEmpty(room.observations) && (
                      <span className="observations">{room.observations}</span>
                    )}
                  </div>

                  {StringHelper.isNotEmpty(room.description) && (
                    <ReadMore id="room-description" cssClass="room-description" text={StringHelper.stripHtml(room.description)} words="36" />
                  )}

                  {StringHelper.isNotEmpty(room.cleaningTax) &&
                    room.cleaningTax > 0 && (
                      <p className='mt-3'>
                        <small>
                          {t('labels.cleaningTax')}:{' '}
                          {NumberHelper.currencyFormatted(room.cleaningTax)}
                        </small>
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={planPricesSectionClass}>

        <div className="col-md-12 col-lg-6 col-xl-7">
          {(StringHelper.isNotEmpty(room.ratePlanTitle) || StringHelper.isNotEmpty(room.ratePlanBoardType)) && (
            <div className="row p-0">
              <div className='col-12'>
                {StringHelper.isNotEmpty(room.ratePlanTitle) && (
                  <h4 style={{ lineHeight: '22px' }}>
                    <span className="description">
                      {room.ratePlanTitle}
                    </span>
                  </h4>
                )}

                {StringHelper.isNotEmpty(room.ratePlanBoardType) && (
                  <p style={{ lineHeight: '22px' }}>
                    <RoomBoardType
                      boardType={room.ratePlanBoardType}
                      boardCharge={room.ratePlanBoardCharge}
                      boardPrice={room.ratePlanBoardPrice}
                    />
                  </p>
                )}
              </div>
            </div>
          )}

          {StringHelper.isNotEmpty(room.ratePlanTerms) && (
            <div className="row p-0">
              <div className='col-md-12 col-lg-11 mb-2'>
                {<ReadMore id="rate-plan-terms" cssClass="room-description" text={StringHelper.stripHtml(room.ratePlanTerms)} words="15" />}
              </div>
            </div>
          )}
        </div>
        <div className="col-md-12 col-lg-5">
          {room.salesBaseRooms.map((saleBase, index) => {
            const useBorder = index !== totalSalesBaseItems;
            return (
              <RoomSaleBase
                key={`sale-base-${room.id}-${index}`}
                saleBase={saleBase}
                privateRoom={room.privateRoom}
                totalAvailable={totalAvailable}
                totalUsed={totalGuests}
                reservationRoom={reservationRoom}
                reservationRooms={reservationRooms}
                autoSelect={autoSelect}
                onSaleBaseChange={(operation, totalRoomGuests) => onAddGuestClick(operation, room, saleBase, totalRoomGuests, index)}
                useBorder={useBorder}
                canSale={totalAvailable > 0}
                isRoomAlreadySelectedInAnotherPlan={isRoomAlreadySelectedInAnotherPlan}
              />
            );
          })}
        </div>
      </div>

    </section>
  );
};

Room.propTypes = {
  room: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  reservationRooms: PropTypes.array.isRequired,
};

export default Room;
