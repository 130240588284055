import * as ActionTypes from './action-types';

export const setRooms = (
  rooms,
  maxNights,
  maxGuests,
  travelArrivalDate,
  travelDepartureDate,
  travelTotalNights,
  travelTotalAdults,
  travelTotalChildren,
  travelChildren
) => ({
  type: ActionTypes.SET_ROOMS,
  payload: {
    ready: true,
    rooms,
    maxNights,
    maxGuests,
    travelArrivalDate,
    travelDepartureDate,
    travelTotalNights,
    travelTotalAdults,
    travelTotalChildren,
    travelChildren,
  },
});

export const setRoomsCleanup = () => ({
  type: ActionTypes.SET_ROOMS_CLEANUP,
  payload: {},
});

export const setFullCleanup = () => ({
  type: ActionTypes.SET_FULL_CLEANUP,
  payload: {},
});
