import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setCurrentLanguage } from '../../../../store/application/thunks';

// region Helpers

const isLanguageValid = (current, languages) => {
  return !(
    current === null ||
    current === '' ||
    !languages ||
    languages.length <= 0
  );
};

const findCurrentLanguage = (languages, current) => {
  return languages.find(
    language => language.code.toLowerCase() === current.toLowerCase()
  );
};

const findLabel = language => {
  return language.code.toLowerCase() === 'pt-br'
    ? 'PT'
    : language.code.toUpperCase();
};

// endregion

const LanguageSelector = props => { 
  const dispatch = useDispatch(); 
  const history = useHistory(); 
  const application = useSelector(state => state.application); 
  const travel = useSelector(state => state.travel); 
  const reservation = useSelector(state => state.reservation); 
  const currencies = useSelector(state => state.currencies); 
  const { widget } = application; 
  const { couponCode, utmSource, askSuiteId } = reservation; 
  const { arrivalDate, departureDate, totalAdults, preSelectedRoom } = travel; 
  const { code: currentCurrency } = currencies.current; 
  const [isOpen, setIsOpen] = useState(false); 
  const { currentLanguage: current, languages } = props; 
 
  const changeLanguage = code => {
    dispatch(
      setCurrentLanguage(
        code,
        arrivalDate,
        departureDate,
        couponCode,
        currentCurrency,
        totalAdults,
        preSelectedRoom,
        utmSource,
        askSuiteId,
        widget,
        history
      )
    );
    setIsOpen(false);
  };

  const toogleOptions = () => {
    setIsOpen(!isOpen);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isLanguageValid(current, languages)) {
      return null;
    }
  });

  const currentLanguage = findCurrentLanguage(languages, current);
  const currentLabel = findLabel(currentLanguage);

  return (
    <div className="languages-selection">
      <div className="dropdown languages-selection">
        <span
          className="btn btn-raised btn-header-actions"
          onClick={toogleOptions}
        >
          <FontAwesomeIcon icon={faGlobe} /> {currentLabel}
        </span>
        {isOpen && (
          <div className="languages">
            <ul className="language-list list-unstyled" data-test="lang-list">
              {languages.map(language => {
                if (language.code === currentLanguage.code) {
                  return null;
                }

                return (
                  <li
                    key={language.code}
                    onClick={() => {
                      changeLanguage(language.code);
                    }}
                  >
                    <span>{language.name}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

LanguageSelector.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
};

export default LanguageSelector;
