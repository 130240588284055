import React from 'react';
import PropTypes from 'prop-types';

const FormCheckbox = props => {
  const { id, name, label, checked, onInputChange, onInputRealChange } = props;

  const groupClasses = 'checkbox-x';
  const inputClasses = '';

  return (
    <div className={groupClasses}>
      <label>
        <input
          id={id}
          name={name}
          type="checkbox"
          className={inputClasses}
          checked={checked}
          onChange={event => {
            const v = event.target.checked;
            const n = event.target.name;

            onInputChange(v, n);

            if (typeof onInputRealChange === 'function') {
              onInputRealChange();
            }
          }}
        />{' '}
        {label}
      </label>
    </div>
  );
};

FormCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputRealChange: PropTypes.func,
};

export default FormCheckbox;
