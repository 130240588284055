import React from 'react';
import PropTypes from 'prop-types';
import {
  faBed,
  faCheck,
  faShoppingBag,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import Step from './Step';
import {
  STEP_AVAILABILITY,
  STEP_CHECKOUT,
  STEP_PRODUCTS,
  STEP_SUCCESS,
} from '../../../../helpers/steps';
import UrlHelper from '../../../../helpers/url-helper';

const Stepper = props => {
  const { 
    arrivalDate, 
    departureDate, 
    couponCode, 
    adults, 
    children,
    askSuiteId, 
    widget,
    current 
  } = props;

  const stepsState = {
    availability: [
      STEP_AVAILABILITY,
      STEP_PRODUCTS,
      STEP_CHECKOUT,
      STEP_SUCCESS,
    ].includes(current),
    products: [STEP_PRODUCTS, STEP_SUCCESS].includes(current),
    checkout: [STEP_CHECKOUT, STEP_SUCCESS].includes(current),
    success: current === STEP_SUCCESS,
  };

  let useLink = true;

  if (
    !stepsState.availability &&
    !stepsState.products &&
    !stepsState.checkout &&
    !stepsState.success
  ) {
    useLink = false;
    stepsState.availability = true;
  }

  let roomsUrl = '';
  let productsUrl = '';
  let checkoutUrl = '';

  if (useLink) {
    roomsUrl = UrlHelper.toInternalRoute(
      'rooms',
      arrivalDate,
      departureDate,
      couponCode,
      '', 
      adults, 
      children, 
      null, 
      askSuiteId,
      widget
    );
    productsUrl = UrlHelper.toInternalRoute(
      'products',
      arrivalDate,
      departureDate,
      couponCode,
      '', 
      adults, 
      children, 
      null, 
      askSuiteId,
      widget
    );
    checkoutUrl = UrlHelper.toInternalRoute(
      'checkout',
      arrivalDate,
      departureDate,
      couponCode,
      '', 
      adults, 
      children, 
      null, 
      askSuiteId,
      widget
    );
  }

  return (
    <div className="md-stepper-horizontal orange">
      <Step
        icon={faBed}
        step={1}
        label={'Hostel'}
        active={stepsState.products && stepsState.availability}
        link={useLink}
        url={roomsUrl}
      />

      {stepsState.products && (
        <Step
          icon={faShoppingBag}
          step={2}
          label={'Products'}
          active={stepsState.products}
          link={stepsState.products && useLink}
          url={productsUrl}
        />
      )}

      {!stepsState.products && (
        <Step
          icon={faUser}
          step={2}
          label={'Checkout'}
          active={stepsState.checkout}
          link={stepsState.checkout && useLink}
          url={checkoutUrl}
        />
      )}

      <Step
        icon={faCheck}
        step={3}
        label={'Success'}
        active={stepsState.success}
      />
    </div>
  );
};

Stepper.propTypes = {
  arrivalDate: PropTypes.string,
  departureDate: PropTypes.string,
  couponCode: PropTypes.string,
  current: PropTypes.string,
};

export default Stepper;
