import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import FsLightbox from 'fslightbox-react';
import StringHelper from '../../../../helpers/string-helper';
import defaultHostelThumbnail from '../../../../images/no-picture-room.png';

const RoomGallery = props => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const { mainPhotoUrl, mainPhotoThumbnailUrl, photos } = props;

  const hasMainPhoto =
    StringHelper.isNotEmpty(mainPhotoUrl) &&
    StringHelper.isNotEmpty(mainPhotoThumbnailUrl);

  const hasPhotos = Array.isArray(photos) && photos.length > 0;

  const onOpenLightboxHandler = number => {
    if (!hasPhotos) {
      return;
    }
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  const photosFiles = photos.map(photo => photo.url);

  return (!hasPhotos) ?
    <img
      className="img rounded img-fluid room-thumbnail"
      src={defaultHostelThumbnail}
      alt="Room Default Pic"
    />
    :
    (
      <Fragment>
        <span className={hasMainPhoto ? 'as-link' : ''}>
          <img
            className="img rounded img-fluid room-thumbnail"
            src={hasMainPhoto ? mainPhotoUrl : defaultHostelThumbnail}
            onClick={() => onOpenLightboxHandler(1)}
            alt=""
          />
        </span>
        <FsLightbox
          toggler={lightboxController.toggler}
          slide={lightboxController.slide}
          sources={photosFiles}
          type="image"
        />
      </Fragment>
    );
};

RoomGallery.propTypes = {
  mainPhotoUrl: PropTypes.string,
  mainPhotoThumbnailUrl: PropTypes.string,
  photos: PropTypes.array.isRequired,
};

export default RoomGallery;
