import i18next from 'i18next';

const i18nHelper = {
  async changeLanguage(lang, callback) {
    return i18next.changeLanguage(lang, callback);
  },

  validLanguages: ['pt-BR', 'en', 'es'],

  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',

  cacheKey: process.env.REACT_APP_LANGUAGE_CACHE_KEY || 'lng',

  cacheDefKey: process.env.REACT_APP_LANGUAGE_CACHE_DEFAULT_KEY || 'lng.def',

  verifyDefault: () => {
    const lang = localStorage.getItem(i18nHelper.cacheKey);

    localStorage.setItem(
      i18nHelper.cacheDefKey,
      lang && lang !== '' ? '0' : '1'
    );
  },

  isUsingDefaultLanguage: () => {
    return !!+localStorage.getItem(i18nHelper.cacheDefKey);
  },

  fixLanguageCode: (code, defaultCode = i18nHelper.defaultLanguage) => {
    if (code === null || code === '') {
      return defaultCode;
    }

    let languageCode = code.toLowerCase();

    if (
      languageCode.toLowerCase() === 'pt' ||
      languageCode.toLowerCase() === 'pt-br'
    ) {
      languageCode = 'pt-BR';
    }

    return languageCode;
  },
};

export default i18nHelper;
