import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorHeader from '../../components/Structure/Header/Error';

const UnknownPropertyPage = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <ErrorHeader
        title={t('errors.propertyOps')}
        message={t('errors.propertyNotFound')}
      />
    </Fragment>
  );
};

export default UnknownPropertyPage;
