import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ModalTitle from '../../Modal/Title';
import ChooseButtons from '../../ChooseButtons';
import ChildrenAges from './ChildrenAges';

const AvailabilityGuests = props => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const {
    totalAdults,
    totalChildren,
    children,
    onPersonChange,
    onChildAgeChange,
    onAvailabilityFetchHandler,
  } = props;
  const propertyConfig = useSelector(state => state.property.config);
  const { acceptChildren, minimunChildrenAge } = propertyConfig;

  // region Events Handler
  const onCloseModalHandler = () => setShowModal(false);
  const onShowModalHandler = () => setShowModal(true);
  // endregion

  const adultsText = (totalAdults > 0) ? t('guests.moreAdults', { adults: totalAdults }) : t('guests.noAdults');
  const childrenText = (totalChildren > 0) ? t('guests.moreChildren', { children: totalChildren }) : t('guests.noChildren');

  const Adults = (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-6">
          <h5 className="mb-0">{t('guests.adults')}</h5>
          {/* <span className="small textGreyLight">
            {t('guests.adultsMinAge')}
          </span> */}
        </div>
        <ChooseButtons
          type="peopleNumberSelector"
          className="col-6"
          total={totalAdults}
          onTotalChange={(operation, total) => onPersonChange(total, null)}
        />
      </div>
    </React.Fragment>
  );

  const Children = (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col-6">
          <h5 className="mb-0">{t('guests.children')}</h5>
          <span className="small textGreyLight">
            {t('guests.childrenMinAge')}
          </span>
        </div>
        <ChooseButtons
          type="peopleNumberSelector"
          className="col-6"
          total={totalChildren}
          onTotalChange={(operation, total) => onPersonChange(null, total)}
        />
      </div>
      {children.map((age, index) => (
        <ChildrenAges
          key={`child-${index}`}
          currentChild={index}
          minimunChildrenAge={minimunChildrenAge}
          childAge={age}
          onChildAgeChange={(a, i) => onChildAgeChange(a, i)}
        />
      ))}
    </React.Fragment>
  );

  const completeText = acceptChildren
    ? `${adultsText}, ${childrenText}`
    : adultsText;

  return (
    <div className="col-12 col-lg-7 text-center mb-3 mb-md-5 mb-lg-0">
      <button
        className="btn btn-raised btn-default w-100 w-lg-auto"
        style={{ fontSize: '0.9em' }}
        onClick={onShowModalHandler}
      >
        {completeText}
      </button>

      <Modal
        size="sm"
        dialogClassName="modal-guests"
        show={showModal}
        onHide={onCloseModalHandler}
      >
        <Modal.Header closeButton>
          <Modal.Title as={ModalTitle}>
            {t('titles.accommodations')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Adults}
          {acceptChildren && Children}

          <button
            onClick={() => {
              onAvailabilityFetchHandler();
              onCloseModalHandler();
            }}
            className="btn btn-c-cfm btn-raised btn-default w-100 w-lg-auto"
          >
            {t('labels.confirm')}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

AvailabilityGuests.propTypes = {
  totalAdults: PropTypes.number.isRequired,
  totalChildren: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
  onPersonChange: PropTypes.func.isRequired,
  onChildAgeChange: PropTypes.func.isRequired,
};

export default AvailabilityGuests;
