import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';

const Step = props => {
  const history = useHistory();

  const { icon, active, step, label, link, url } = props;

  const currentClass = `md-step${active ? ' active done' : ''}`;
  const currentSyle = link ? { cursor: 'pointer' } : {};

  return (
    <div className={currentClass}>
      <div
        className="md-step-circle"
        style={currentSyle}
        onClick={() => {
          if (link) {
            history.push(url);
          }
        }}
      >
        <FontAwesomeIcon icon={icon}>
          <span>{step}</span>
        </FontAwesomeIcon>
      </div>
      <div className="md-step-title d-none">{label}</div>
      <div className="md-step-bar-left" />
      <div className="md-step-bar-right" />
    </div>
  );
};

Step.propTypes = {
  icon: PropTypes.any.isRequired,
  active: PropTypes.bool,
  step: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.bool,
  url: PropTypes.string,
};

export default Step;
