import * as ActionTypes from './action-types';

export const setAsEmpty = loaded => ({
  type: ActionTypes.SET_IS_LOADED,
  payload: { loaded },
});

export const setIsLoaded = loaded => ({
  type: ActionTypes.SET_IS_LOADED,
  payload: { loaded },
});

export const setArrivalDate = date => ({
  type: ActionTypes.SET_ARRIVAL_DATE,
  payload: { date },
});

export const setDepartureDate = date => ({
  type: ActionTypes.SET_DEPARTURE_DATE,
  payload: { date },
});

export const setTotalNights = totalNights => ({
  type: ActionTypes.SET_TOTAL_NIGHTS,
  payload: { totalNights },
});

export const setTotalAdults = adults => ({
  type: ActionTypes.SET_TOTAL_ADULTS,
  payload: { adults },
});

export const setTotalChildren = children => ({
  type: ActionTypes.SET_TOTAL_CHILDREN,
  payload: { children },
});

export const setChildAge = (age, index) => ({
  type: ActionTypes.SET_CHILD_AGE,
  payload: { age, index },
});

export const setPreSelectedRoom = preSelectedRoom => ({
  type: ActionTypes.SET_PRE_SELECTED_ROOM,
  payload: { preSelectedRoom },
});

export const setTravelCleanup = () => ({
  type: ActionTypes.SET_TRAVEL_CLEANUP,
  payload: {},
});
