import * as ActionTypes from './action-types';

const initialState = {
  ready: false,
  ads: [],
};

export default function reduce(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ActionTypes.SET_PRODUCTS: {
      return {
        ...state,
        ready: payload.ready,
        ads: payload.ads,
      };
    }

    case ActionTypes.SET_PRODUCTS_CLEANUP: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}
