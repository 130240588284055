import { http } from '../../helpers/http';
import * as ProductsService from '../../services/Products';
import * as ProductsActions from './actions';
import ObjectHelper from '../../helpers/object-helper';
import NumberHelper from '../../helpers/number-helper';
import StringHelper from '../../helpers/string-helper';

export const fetchProducts = (callback, errorCallback, forceUpdate) => {
  return async (dispatch, getState) => {
    const {
      application: {
        ready: applicationReady,
        property: { uid, subpropertyUid },
        i18n: { currentLanguage },
      },
      travel: { loaded: travelLoaded },
    } = getState();

    if (!applicationReady || (travelLoaded && !forceUpdate)) {
      return;
    }

    const [success, data] = await http.request(
      ProductsService.SERVICE_FETCH_PRODUCTS,
      {
        l: currentLanguage.toString().toLowerCase(),
        h: uid,
        s: subpropertyUid,
      }
    );

    if (success) {
      const { ads: baseProductsAds } = data;

      let productsAds = [];

      if (Array.isArray(baseProductsAds)) {
        productsAds = baseProductsAds.map(product => {
          let name = ObjectHelper.pod(product, 'prodName', '');
          let description = ObjectHelper.pod(product, 'prodDesc', '');
          const details = ObjectHelper.pod(product, 'info', []);

          if (Array.isArray(details) && details.length > 0) {
            if (
              details[0].hasOwnProperty('name') &&
              StringHelper.isNotEmpty(details[0].name)
            ) {
              // eslint-disable-next-line prefer-destructuring
              name = details[0].name;
            }

            if (
              details[0].hasOwnProperty('description') &&
              StringHelper.isNotEmpty(details[0].description)
            ) {
              // eslint-disable-next-line prefer-destructuring
              description = details[0].description;
            }
          }

          return {
            id: +ObjectHelper.pod(product, 'prod_ad_id', 0),
            productId: +ObjectHelper.pod(product, 'product_id', 0),
            price: NumberHelper.toFloat(ObjectHelper.pod(product, 'price', 0)),
            limit: +ObjectHelper.pod(product, 'purchase_limit', 0),
            photo: ObjectHelper.pod(product, 'photo', ''),
            name,
            description,
          };
        });
      }

      const reservationProducts = productsAds.map(product => {
        return {
          id: product.id,
          name: product.name,
          price: product.price,
          limit: product.limit,
          total: 0,
        };
      });

      dispatch(ProductsActions.setProducts(productsAds));
      callback(reservationProducts, forceUpdate);
    } else {
      dispatch(ProductsActions.setProductsCleanup());
      errorCallback();
    }
  };
};
