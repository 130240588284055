import * as ActionTypes from './action-types';

export const setReady = isReady => ({
  type: ActionTypes.SET_READY,
  payload: { isReady },
});

export const setCurrentStep = (currentStep, clearFinished = true) => ({
  type: ActionTypes.SET_CURRENT_STEP,
  payload: { currentStep, clearFinished },
});

export const setLoading = isLoading => ({
  type: ActionTypes.SET_LOADING,
  payload: { isLoading },
});

export const setLanguage = language => ({
  type: ActionTypes.SET_LANGUAGE,
  payload: { language },
});

export const setSuccess = (successMessage, tenantBookingId, paymentMethod, pixQrCode, pixPaymentLine) => ({
  type: ActionTypes.SET_SUCCESS,
  payload: { successMessage, tenantBookingId, paymentMethod, pixQrCode, pixPaymentLine },
});

export const setIsDefaultLanguage = (defaultLanguage, usingDefault) => ({
  type: ActionTypes.SET_DEFAULT_LANGUAGE,
  payload: { defaultLanguage, usingDefault },
});

export const setGlobalProperty = (
  uid,
  subpropertyUid,
  code,
  subpropertyCode,
  domain,
  language,
  available,
  gtm
) => ({
  type: ActionTypes.SET_CONNECTED_PROPERTY,
  payload: {
    uid,
    subpropertyUid,
    code,
    subpropertyCode,
    domain,
    language,
    available,
    gtm,
  },
});

export const setUnknownProperty = () => ({
  type: ActionTypes.SET_UNKNOWN_PROPERTY,
  payload: {},
});

export const setWidget = widget => ({
  type: ActionTypes.SET_WIDGET,
  payload: {
    widget,
  },
});
