import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates/esm';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Responsive from 'react-responsive';
import * as TravelActions from '../../store/travel/actions';
import * as AvailabilityThunks from '../../store/availability/thunks';
import AvailabilityGuests from './Guests';
import DateTimeHelper from '../../helpers/date-time-helper';
import ToasterHelper from '../../helpers/toaster-helper';
import StringHelper from '../../helpers/string-helper';
import UrlHelper from '../../helpers/url-helper';

const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Default = props => <Responsive {...props} minWidth={768} />;

const Availability = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const travel = useSelector(state => state.travel);
  const reservation = useSelector(state => state.reservation);
  const application = useSelector(state => state.application);
  const { couponCode } = reservation;
  const { widget } = application;
  const isWidget = widget === '1';
  const property = useSelector(state => state.property);
  const {
    name,
    config: propertyConfig
  } = property;
  const {
    releasePeriod,
    nightsLimit,
    reservationMaxMonthsAhead,
  } = propertyConfig;
  const {
    loaded: travelLoaded,
    arrivalDate: currentArrivalDate,
    departureDate: currentDepartureDate,
    totalNights,
    totalAdults,
    totalChildren,
    children,
  } = travel;
  const [focusedInput, setFocusedInput] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [fetching, setFetching] = useState(false);
  const showPersons = true;
  const today = moment();
  const maxDate =
    +reservationMaxMonthsAhead > 0
      ? today.add(+reservationMaxMonthsAhead, 'months')
      : null;

  if (arrivalDate === null && currentArrivalDate !== null) {
    setArrivalDate(moment(currentArrivalDate));
  }

  if (departureDate === null && currentDepartureDate !== null) {
    setDepartureDate(moment(currentDepartureDate));
  }

  // region Events Handler

  const onDatesChangeHandler = ({ startDate, endDate }) => {
    const realStartDate = startDate;
    let realEndDate = endDate;
    let nights = 0;

    if (DateTimeHelper.isDate(startDate) && DateTimeHelper.isDate(endDate)) {
      nights = endDate.diff(startDate, 'days');
      if (+nightsLimit > 0 && nights > nightsLimit) {
        ToasterHelper.error(t('errors.nightsLimit', { nights: nightsLimit }));
        realEndDate = null;
      }
    }

    setArrivalDate(realStartDate);
    setDepartureDate(realEndDate);

    let formattedArrivalDate = null;
    let formattedDepartureDate = null;

    if (DateTimeHelper.isDate(realStartDate)) {
      formattedArrivalDate = realStartDate.format('YYYY-MM-DD');
    }

    if (DateTimeHelper.isDate(realEndDate)) {
      // noinspection JSObjectNullOrUndefined
      formattedDepartureDate = realEndDate.format('YYYY-MM-DD');
    }

    dispatch(TravelActions.setArrivalDate(formattedArrivalDate));
    dispatch(TravelActions.setDepartureDate(formattedDepartureDate));
    dispatch(TravelActions.setTotalNights(nights));
  };

  const onDatesFocusHandler = fi => {
    setFocusedInput(fi);
  };

  const isDayBlockedHandler = () => { };

  const onPersonChangeHandler = (updatedAdults, updatedChildren) => {
    if (updatedAdults !== null) {
      dispatch(TravelActions.setTotalAdults(updatedAdults));
    }

    if (updatedChildren !== null) {
      dispatch(TravelActions.setTotalChildren(updatedChildren));
    }
  };

  const onChildAgeChangeHandler = (age, index) => {
    dispatch(TravelActions.setChildAge(age, index));
  };

  const onAvailabilityFetchHandler = () => {
    if (fetching || travelLoaded) {
      if (fetching) {
        ToasterHelper.error(t('errors.fetchingAvailability'));
      } else if (travelLoaded) {
        ToasterHelper.error(t('errors.travelLoaded'));
      }
      return;
    }

    if (
      StringHelper.isEmpty(currentArrivalDate) ||
      StringHelper.isEmpty(currentDepartureDate)
    ) {
      ToasterHelper.error(t('errors.emptyAvailabilityDates'));
      return;
    }

    if (+nightsLimit > 0 && totalNights > nightsLimit) {
      ToasterHelper.error(t('errors.nightsLimit', { nights: nightsLimit }));
      return;
    }

    setFetching(true);

    dispatch(
      AvailabilityThunks.fetchAvailability(
        currentArrivalDate,
        currentDepartureDate,
        couponCode,
        totalAdults,
        totalChildren,
        children,
        () => {
          setFetching(false);

          history.push(
            UrlHelper.to('rooms', {
              arrival: currentArrivalDate,
              departure: currentDepartureDate,
              coupon: couponCode,
              adults: totalAdults,
              children: totalChildren,
              widget: widget ?? ''
            })
          );
        },
        () => {
          setFetching(false);

          if (releasePeriod > 0) {
            ToasterHelper.error(
              t('errors.availabilityEmptyResultset', {
                period: releasePeriod,
                plural: releasePeriod > 1 ? 's' : '',
              })
            );
          } else {
            ToasterHelper.error(
              t('errors.availabilityEmptyResultsetReleasePeriod')
            );
          }
        }
      )
    );
  };

  // endregion

  // region Calendar
  const Calendar = props => {
    const { numberOfMonths, readOnly } = props;

    const calendarPhrases = {
      calendarLabel: t('calendar.calendarLabel'),
      roleDescription: t('calendar.roleDescription'),
      closeDatePicker: t('calendar.closeDatePicker'),
      focusStartDate: t('calendar.focusStartDate'),
      clearDate: t('calendar.clearDate'),
      clearDates: t('calendar.clearDates'),
      jumpToPrevMonth: t('calendar.jumpToPrevMonth'),
      jumpToNextMonth: t('calendar.jumpToNextMonth'),
      keyboardShortcuts: t('calendar.keyboardShortcuts'),
      showKeyboardShortcutsPanel: t('calendar.showKeyboardShortcutsPanel'),
      hideKeyboardShortcutsPanel: t('calendar.hideKeyboardShortcutsPanel'),
      openThisPanel: t('calendar.openThisPanel'),
      enterKey: t('calendar.enterKey'),
      leftArrowRightArrow: t('calendar.leftArrowRightArrow'),
      upArrowDownArrow: t('calendar.upArrowDownArrow'),
      pageUpPageDown: t('calendar.pageUpPageDown'),
      homeEnd: t('calendar.homeEnd'),
      escape: t('calendar.escape'),
      questionMark: t('calendar.questionMark'),
      selectFocusedDate: t('calendar.selectFocusedDate'),
      moveFocusByOneDay: t('calendar.moveFocusByOneDay'),
      moveFocusByOneWeek: t('calendar.moveFocusByOneWeek'),
      moveFocusByOneMonth: t('calendar.moveFocusByOneMonth'),
      moveFocustoStartAndEndOfWeek: t('calendar.moveFocustoStartAndEndOfWeek'),
      returnFocusToInput: t('calendar.returnFocusToInput'),
      keyboardForwardNavigationInstructions: t(
        'calendar.keyboardForwardNavigationInstructions'
      ),
      keyboardBackwardNavigationInstructions: t(
        'calendar.keyboardBackwardNavigationInstructions'
      ),
      chooseAvailableStartDate: t('calendar.chooseAvailableStartDate'),
      chooseAvailableEndDate: t('calendar.chooseAvailableEndDate'),
      chooseAvailableDate: t('calendar.chooseAvailableDate'),
      dateIsUnavailable: t('calendar.dateIsUnavailable'),
      dateIsSelected: t('calendar.dateIsSelected'),
      dateIsSelectedAsStartDate: t('calendar.dateIsSelectedAsStartDate'),
      dateIsSelectedAsEndDate: t('calendar.dateIsSelectedAsEndDate'),
    };

    return (
      <DateRangePicker
        startDate={arrivalDate}
        startDateId="availabilityArrivalDate"
        startDatePlaceholderText={t('calendar.arrivalDate')}
        endDate={departureDate}
        endDateId="availabilityDepartureDate"
        endDatePlaceholderText={t('calendar.departureDate')}
        onDatesChange={onDatesChangeHandler}
        focusedInput={focusedInput}
        onFocusChange={onDatesFocusHandler}
        displayFormat={t('calendar.dateFormat')}
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={numberOfMonths}
        block={false}
        small={false}
        withFullScreenPortal={false}
        anchorDirection={'left'}
        orientation={'horizontal'}
        minimumNights={1}
        isDayBlocked={isDayBlockedHandler}
        showClearDates={true}
        maxDate={maxDate}
        phrases={calendarPhrases}
        readOnly={readOnly}
      />
    );
  };
  // endregion

  return (
    <div className="card-body availability-wrapper">
      <div className="row">
        {!isWidget && <div className="row-fluid calendarPropertyName">
          {name}
        </div>}
        <div className="col-12 col-md-6 col-lg-6 pr-lg-0">
          <div className="form-group mr-md-6 mb-6 w-lg-40">
            <Default>
              <Calendar numberOfMonths={2} readOnly={false} />
            </Default>
            <Mobile>
              <Calendar numberOfMonths={1} readOnly={true} />
            </Mobile>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-5 pl-lg-0">
          <div className="row availability">
            {showPersons && (
              <AvailabilityGuests
                totalAdults={totalAdults}
                totalChildren={totalChildren}
                children={children}
                onPersonChange={onPersonChangeHandler}
                onChildAgeChange={onChildAgeChangeHandler}
                onAvailabilityFetchHandler={onAvailabilityFetchHandler}
              />
            )}
            <div className="col-12 col-lg-5 text-center mb-3">
              <button
                className="btn btn-raised btn-default w-100 w-lg-auto"
                onClick={onAvailabilityFetchHandler}
              >
                {fetching ? t('labels.consulting') : t('labels.consult')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Availability;
