import * as ActionTypes from './action-types';

const initialState = {
  ready: false,
  name: null,
  uid: null,
  gtm: null,
  logoUrl: null,
  email: null,
  phoneNumber: null,
  whatsapp: null,
  address: null,
  city: null,
  state: null,
  country: null,
  countryCode: null,
  countryCurrencyCode: null,
  countryCurrencyName: null,
  countryCurrencySymbol: null,
  latitude: null,
  longitude: null,
  aboutInfoText: null,
  aboutCallInfoText: null,
  termsInfoText: null,
  checkinDetailsInfoText: null,
  cancelationPolicyInfoText: null,
  depositBankTransferInfoText: null,
  photos: [],
  widePhotos: [],
  mainPhotoUrl: null,
  mainPhotoThumbnailUrl: null,
  config: {
    nightsLimit: null,
    reservationMaxGuestsLimit: null,
    reservationMaxMonthsAhead: null,
    creditCardPaymentEnabled: false,
    bankTransferPaymentEnabled: false,
    depositPercentage: null,
    depositExpireDays: null,
    acceptChildren: null,
    minimunChildrenAge: null,
    paymentGatewayEnabled: false,
    paymentGatewayInstallmentsEnabled: false,
    paymentGatewayInstallmentsLimit: 1,
    paymentGatewayInstallments: [],
    paymentGatewayPixEnabled: false,
  },
  amenities: [],
};

export default function reduce(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ActionTypes.SET_PROPERTY: {
      return { ...state, ...payload };
    }

    case ActionTypes.SET_PROPERTY_IS_READY: {
      return { ...state, ready: true };
    }

    default: {
      return state;
    }
  }
}
