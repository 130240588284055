import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import StringHelper from '../../helpers/string-helper';

const TermsPopup = ({ propertyTerms, language, showTerms, setShowTerms }) => {

  const { t } = useTranslation();

  return <Modal
    show={showTerms}
    size="lg"
    onHide={() => {
      setShowTerms(false);
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{t('titles.terms')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{(StringHelper.isEmpty(propertyTerms) ? getDefaultTermsPortuguese(language) : parse(propertyTerms))}</Modal.Body>
    <Modal.Footer>
      <Button
        onClick={() => {
          setShowTerms(false);
        }}
      >
        {t('labels.close')}
      </Button>
    </Modal.Footer>
  </Modal>;
}

const getDefaultTermsPortuguese = (language) => {
  if (language === 'pt-br') {
    return <div><b>Condições Gerais</b>
      <br />
      Taxa de cancelamento: Caso precise cancelar a sua reserva, consulte com a nossa recepção para saber se terá ou não penalidade pelo cancelamento.
      <br />
      Não comparecimento: Em caso de não comparecimento, a propriedade se reserva o direito de cobrar um porcentual ou a totalidade do valor da sua reserva.
      <br /><br />
      <b>Exceções</b>
      <br />
      Reservas especiais: Reservas feitas com tarifas promocionais ou pacotes especiais podem ter políticas de cancelamento diferentes.
      <br />
      Grupos e eventos: Reservas para grupos ou eventos podem ter políticas de cancelamento personalizadas.
      <br />
      Períodos de alta temporada: Durante períodos de alta temporada, as políticas de cancelamento podem ser mais restritivas.
      <br /><br />
      <b>Procedimento de Cancelamento</b>
      <br />
      Para cancelar ou alterar sua reserva, entre em contato com a nossa recepçãoa de boa
      <br />
      Forneça o número da reserva e o nome do hóspede.
      <br />
      Aguarde confirmação do cancelamento ou alteração.
    </div>

  } else if (language === 'es') {

    return <div><b>Condiciones Generales</b>
      <br />
      Tarifa de cancelación: Si necesitas cancelar tu reserva, consulta con nuestra recepción para saber si se aplicará alguna penalización por la cancelación.
      <br />
      No presentación: En caso de no presentación, la propiedad se reserva el derecho de cobrar un porcentaje o la totalidad del valor de tu reserva.
      <br /><br />

      <b>Excepciones</b>
      <br />
      Reservas especiales: Las reservas realizadas con tarifas promocionales o paquetes especiales pueden tener políticas de cancelación diferentes.
      <br />
      Grupos y eventos: Las reservas para grupos o eventos pueden tener políticas de cancelación personalizadas.
      <br />
      Temporadas altas: Durante las temporadas altas, las políticas de cancelación pueden ser más restrictivas.
      <br /><br />

      <b>Procedimiento de Cancelación</b>
      <br />
      Para cancelar o modificar tu reserva, contacta con nuestra recepción.
      <br />
      Proporciona el número de reserva y el nombre del huésped.
      <br />
      Espera la confirmación de la cancelación o modificación.</div>

  } else {

    return <div><b>General Conditions</b>
      <br />
      Cancellation Fee: If you need to cancel your reservation, please consult our reception to determine whether a cancellation penalty applies.
      <br />
      No-Show: In the event of a no-show, the property reserves the right to charge a percentage or the full amount of your reservation.
      <br /><br />
      <b>Exceptions</b>
      <br />
      Special Reservations: Reservations made with promotional rates or special packages may have different cancellation policies.
      <br />
      Groups and Events: Reservations for groups or events may have customized cancellation policies.
      <br />
      Peak Seasons: During peak seasons, cancellation policies may be more restrictive.
      <br /><br />
      <b>Cancellation Procedure</b>
      <br />
      To cancel or modify your reservation, contact our reception.
      <br />
      Provide the reservation number and the guest's name.
      <br />
      Wait for confirmation of the cancellation or modification.</div>
  }
}

export default TermsPopup;
