import HomePage from './HomePage';
import RoomsPage from './RoomsPage';
import ProductsPage from './ProductsPage';
import CheckoutPage from './CheckoutPage';
import SuccessPage from './SuccessPage';
import SplashPage from './SplashPage';
import UnknownPropertyPage from './UnknownPropertyPage';

export default {
  HomePage,
  RoomsPage,
  ProductsPage,
  CheckoutPage,
  SuccessPage,
  SplashPage,
  UnknownPropertyPage,
};
