import React from 'react';
import ReactLoading from 'react-loading';
import './index.scss';

const Loading = () => (
  <div className="global-loading">
    <ReactLoading type="bars" color="#c7d92f" height={'10%'} width={'10%'} />
  </div>
);

export default Loading;
