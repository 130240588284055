const CurrenciesHelper = {
  onlyDefault: true,

  default: process.env.REACT_APP_DEFAULT_CURRENCY || 'BRL',

  cacheKey: process.env.REACT_APP_CURRENCY_CACHE_KEY || 'currency',

  findDefault: defaultCurrency => {
    if (CurrenciesHelper.onlyDefault) {
      return defaultCurrency;
    }

    let mainCurrency = defaultCurrency;

    if (mainCurrency === null || mainCurrency === '') {
      mainCurrency = CurrenciesHelper.default;
    }

    const currency = localStorage.getItem(CurrenciesHelper.cacheKey);

    if (currency !== null && currency !== '') {
      return currency;
    }

    CurrenciesHelper.save(mainCurrency);

    return mainCurrency;
  },

  save: code => {
    localStorage.setItem(CurrenciesHelper.cacheKey, code);
  },
};

export default CurrenciesHelper;
