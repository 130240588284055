import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { store } from './store/Redux';
import i18nHelper from './helpers/i18n-helper';
import Loading from './components/Loading';
import App from './containers/App';

i18nHelper.verifyDefault();

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
