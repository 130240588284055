import React, { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import * as ApplicationActions from '../../store/application/actions';
import * as ApplicationThunks from '../../store/application/thunks';
import Loading from '../../components/Loading';
import DateTimeHelper from '../../helpers/date-time-helper';
import StringHelper from '../../helpers/string-helper';

const verifyProperty = (
  defaultProperty,
  urlProperty,
  urlSubProperty,
  arrival,
  departure,
  coupon,
  dispatch
) => {
  let property = '';
  let subproperty = '';
  let redirect = false;

  if (urlProperty === null) {
    if (defaultProperty !== '') {
      property = defaultProperty;
      redirect = true;
    } else {
      dispatch(ApplicationActions.setUnknownProperty());

      return null;
    }
  } else {
    property = urlProperty;
  }

  if (urlSubProperty !== null && urlSubProperty !== undefined) {
    subproperty = urlSubProperty;
  }

  if (redirect) {
    let qs = '';

    if (
      DateTimeHelper.isUnformattedDate(arrival) &&
      DateTimeHelper.isUnformattedDate(departure)
    ) {
      qs += `?arrival=${arrival}&departure=${departure}`;
    }

    if (StringHelper.isNotEmpty(coupon)) {
      qs += `${qs !== '' ? '&' : '?'}coupon=${coupon}`;
    }

    window.location.href = `/${property}${
      subproperty !== '' ? `/${subproperty}` : ''
    }${qs}`;

    return null;
  }

  const domain = window.location.host || '';

  dispatch(
    ApplicationThunks.setConnectedProperty(property, subproperty, domain)
  );

  return null;
};

const checkUrlPropertySlug = match => {
  if (match && match.params) {
    const { property } = match.params;

    return property;
  }

  return null;
};

const checkUrlSubPropertySlug = match => {
  if (match && match.params) {
    const { subproperty } = match.params;

    if(['rooms', 'products', 'checkout', 'success'].includes(subproperty)){
      return null;
    }

    return subproperty;
  }

  return null;
};

const SplashPage = () => {
  /** @var {Function} dispatch */
  const dispatch = useDispatch();
  const match = useRouteMatch('/:property/:subproperty?/:page?');
  const urlProperty = checkUrlPropertySlug(match);
  const urlSubProperty = checkUrlSubPropertySlug(match);
  const application = useSelector(state => state.application);
  const { defaultProperty } = application.property;
  const location = useLocation();
  const { search: qs } = location;
  const urlParams = queryString.parse(qs);
  const { arrival, departure, coupon } = urlParams;

  useEffect(() => {
    verifyProperty(
      defaultProperty,
      urlProperty,
      urlSubProperty,
      arrival,
      departure,
      coupon,
      dispatch
    );
  }, [
    defaultProperty,
    urlProperty,
    urlSubProperty,
    arrival,
    departure,
    coupon,
    dispatch,
  ]);

  return <Loading />;
};

export default SplashPage;
