import * as ActionTypes from './action-types';

const initialState = {
  ready: false,
  arrivalDate: null,
  departureDate: null,
  totalNights: 0,
  totalAdults: 0,
  totalChildren: 0,
  children: [],
  config: {
    reservationMaxNights: 0,
    reservationMaxGuests: 0,
  },
  rooms: [],
};

export default function reduce(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ActionTypes.SET_ROOMS: {
      return {
        ...state,
        ready: payload.ready,
        arrivalDate: payload.travelArrivalDate,
        departureDate: payload.travelDepartureDate,
        totalNights: payload.travelTotalNights,
        totalAdults: payload.travelTotalAdults,
        totalChildren: payload.travelTotalChildren,
        children: payload.travelChildren,
        rooms: payload.rooms,
        config: {
          reservationMaxNights: +payload.maxNights,
          reservationMaxGuests: +payload.maxGuests,
        },
      };
    }

    case ActionTypes.SET_ROOMS_CLEANUP: {
      return { ...state, rooms: [] };
    }

    case ActionTypes.SET_FULL_CLEANUP: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}
