export const SERVICE_RESERVATION_SAVE = {
  endpoint: '/save',
  method: 'post',
  hostel: true,
};

export const SERVICE_RESERVATION_LOADED = {
  type: 'SERVICE_RESERVATION_LOADED',
  payload: true,
};

export const SERVICE_RESERVATION_NO_LOADED = {
  type: 'SERVICE_RESERVATION_LOADED',
  payload: false,
};
