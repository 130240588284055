export const SET_AS_EMPTY = 'travel/SET_AS_EMPTY';
export const SET_IS_LOADED = 'travel/SET_IS_LOADED';
export const SET_ARRIVAL_DATE = 'travel/SET_ARRIVAL_DATE';
export const SET_DEPARTURE_DATE = 'travel/SET_DEPARTURE_DATE';
export const SET_TOTAL_NIGHTS = 'travel/SET_TOTAL_NIGHTS';
export const SET_TOTAL_ADULTS = 'travel/SET_TOTAL_ADULTS';
export const SET_TOTAL_CHILDREN = 'travel/SET_TOTAL_CHILDREN';
export const SET_CHILD_AGE = 'travel/SET_CHILD_AGE';
export const SET_PRE_SELECTED_ROOM = 'travel/SET_PRE_SELECTED_ROOM';
export const SET_TRAVEL_CLEANUP = 'travel/SET_TRAVEL_CLEANUP';
