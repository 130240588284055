import axios from 'axios';

const httpHostelClient = axios.create();

httpHostelClient.interceptors.request.use(
  config => {
    return new Promise(resolve => {
      resolve(config);
    });
  },

  error => {
    return Promise.reject(error);
  }
);

httpHostelClient.interceptors.response.use(
  response => {
    if (
      response &&
      response.hasOwnProperty('data') &&
      response.hasOwnProperty('config')
    ) {
      // console.log(response.config.url, response.data);
    }

    return response;
  },

  error => {
    return Promise.reject(error);
  }
);

export default httpHostelClient;
