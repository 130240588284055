import * as ActionTypes from './action-types';

const initialState = {
  ready: false,
  options: [],
};

export default function reduce(state = initialState, { type, payload = {} }) {
  switch (type) {
    case ActionTypes.SET_COUNTRIES: {
      return { ...state, ready: true, options: [...payload] };
    }

    default: {
      return state;
    }
  }
}
