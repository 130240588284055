import React, { Fragment } from 'react';
import toast from 'toasted-notes';
import {
  faExclamationCircle,
  faInfoCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ToasterHelper = {
  message: (text, type) => {
    const position = 'top';
    const duration = 10000;

    let icon = null;
    let iconClass = 'toast-icon';
    let textClass = 'toast-content';

    switch (type) {
      case 'error': {
        icon = faExclamationCircle;
        iconClass += ' text-danger';
        textClass += ' text-danger font-weight-bold';

        break;
      }

      case 'success': {
        icon = faCheckCircle;
        iconClass += ' text-success';
        textClass += ' text-success font-weight-bold';

        break;
      }

      case 'default':
      default: {
        icon = faInfoCircle;
        textClass += ' font-weight-bold';

        break;
      }
    }

    const message = (
      <Fragment>
        <FontAwesomeIcon className={iconClass} icon={icon} />
        <span className={textClass}>{text}</span>
      </Fragment>
    );

    toast.notify(message, { position, type, duration });
  },

  default: text => {
    ToasterHelper.message(text, 'default');
  },

  error: text => {
    ToasterHelper.message(text, 'error');
  },

  success: text => {
    ToasterHelper.message(text, 'success');
  },
};

export default ToasterHelper;
